export const ru_lang = {
  "data": {
    "detailsOfCalculation.fields.additions": "Надбавки",
    "crudForm.field.forHour": "за час",
    "crud.field.mainPortId.title": "Является терминалом порта",
    "form.field.organization.factAddress": "Фактический адрес",
    "form.field.commission.container.price": "За контейнер",
    "crud.field.billing.relative": "Относительная",
    "form.field.iAm": "Я являюсь",
    "form.field.addDocument": "Добавить документ",
    "crud.field.organizationClient.title": "Организация (клиент)",
    "reports.types.ORDER_REQUEST_COUNT": "Количество оформленных заказов",
    "orderCalculation.hidePriceLabelHelp": "По вашему запросу система нашла несколько вариантов маршрутов и стоимости. Войдите/зарегистрируйтесь для просмотра.",
    "organizationsProfiles.status.REJECTED": "Отклонен",
    "profile.data.title": "Ваши данные",
    "form.order.contactPerson": "Контактное лицо",
    "modal.changePassword": "Форма изменения пароля",
    "subscription.switchToFree.text": "Подтвердите переход на бесплатную подписку. Неиспользованные остатки доступных расчетов будут аннулированы.",
    "orderForm.modal.driverName": "ФИО водителя",
    "crudForm.field.planeType": "Тип самолета",
    "crud.field.showDetails": "Показать детали",
    "crudForm.field.id": "ID",
    "crud.form.view.title": "Просмотр записи",
    "crud.field.departurePlace.button.add": "Добавить данные грузоотправителя",
    "crud.field.passwordReset.title": "Сбросить пароль",
    "detailsOfCalculation.callManagerResponse.title": "Запрос отправлен",
    "detailsOfCalculation.discussionCalcPrice.title": "Ваш вопрос получен",
    "detailsOfCalculation.discussionCalcPrice.modalSuccessBody": "Свяжемся с Вами как можно скорее.",
    "detailsOfCalculation.services_destination": "Услуги в порту прибытия",
    "crud.form.add.template.title": "Добавление шаблона",
    "detailsOfCalculation.fields.integratorReward": "Вознаграждение экспедитора",
    "crud.field.international.title": "Международный",
    "reports.barChart.LANGUAGE": "Языки",
    "reports.statusTest.empty": "Данные отсутствуют",
    "logistBlock.departureOrIssueDate": "Отправление / выпуск",
    "constants.franchiseTypes.percent": "Процент от страховой суммы",
    "constants.typeOfRoadGeneral.zip": "с учётом ZIP кодов",
    "crud.field.surchargePerItem.title": "Цена за последующие единицы груза.",
    "orderForm.idBolOrAwb": "Коносамент:",
    "form.error.commission.alreadyExist": "Комиссия для данного типа перевозки уже задана",
    "form.order.cargoDescription": "Наименование груза",
    "form.order.cargoDescription.hint": "Запчасти, оборудование, станок и т.п.",
    "detailsOfCalculation.fromDoor": "забор от двери",
    "constants.month.february": "Февраль",
    "ordersList.button.getMinInfo": "Отображать в кратком виде",
    "operatorAdmin.listOfAPIs": "Внешние API",
    "modal.orderInfo.id": "ID",
    "report.unit.lb": "lb",
    "crud.tab.cargoParams": "Ставки",
    "crud.field.fromHub.title": "Откуда",
    "detailsOfCalculation.customsHouseTitle": "Таможенные платежи",
    "crud.field.service.title": "Тип услуги",
    "palletTariff.form.defaultFileName": "Тариф на магистральную перевозку",
    "service.serverIsUnavailable": "Сервер временно недоступен",
    "customsOfCargo.form.calcBtn": "Рассчитать",
    "common.documents.error.fields": "Заполните все поля и загрузите файл",
    "crudForm.field.rateBilling": "Ставка",
    "error.numberAccount.size.20": "Введите корректный номер расчетного счёта (20 цифр)",
    "partner.rates.private": "Приватные",
    "subscription.tariff.current": "Текущий",
    "form.field.commission.integrator.percent": "Процент от суммы перевозки",
    "crud.field.updateDate.title": "Дата обновления",
    "orderForm.homeBill": "Домашний коносамент:",
    "report.unit.kg": "кг",
    "detailsOfCalculation.filters.sortFor": "Сортировать",
    "crud.add.changeParams": "Изменить параметры",
    "detailsOfCalculation.railway_station_destination": "Услуги на станции прибытия",
    "crudForm.dictionary.field.compartmentSize": "Размер отсека, (L, W, H)",
    "crud.field.departurePlace.button.delete": "Удалить данные грузоотправителя",
    "reportResource.title": "Отчет использования ресурсов",
    "common.copy.success": "Данные скопированы в буфер обмена",
    "form.field.countryFrom": "Страна (откуда)",
    "crud.field.executionTime.title": "Время возникновения",
    "form.field.perKM": "Цена за км",
    "customsOfCargo.rate.both": "Таможенная пошлина взимается по комбинированной ставке {mainRate}% от стоимости товара плюс {value} {currency}/{unit}. НДС {nds}%",
    "notifications.readAll": "Отметить все прочитанными",
    "mainMenu.subscriptions.tariff": "Подписки",
    "crudForm.field.hourly": "Почасовая",
    "crud.field.tarificationType.title": "Тарификации",
    "reportResource.report.avg.countExternalVariants": "Среднее количество вариантов расчетов с учетом внешних партнеров",
    "crudForm.field.documentDeadline": "Срок подачи документов, (дн)",
    "person.wizard": "Новый расчет",
    "crud.field.error.category.serviceExists": "Невозможно удалить категорию, к которому привязаны услуги",
    "crud.remove.failed": "Произошла ошибка при удалении.",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.demurrage": "Неверно указано demurrage время. Строка {0}, столбец {1}.",
    "general.cargo.compound.generalBtn": "Сборный груз",
    "crudForm.dictionary.field.maxPalletCount": "Макс. паллетов",
    "deliveryOrders.orderList.headerTable.departurePoint": "Откуда",
    "partner.rates.sea": "Морской фрахт",
    "detailsOfCalculation.dayRP": "дня",
    "crud.field.allPorts": "Все порты",
    "common.equalsfortariffAddress": "Неверный выбор пути,согласно выбранной тарифкации",
    "deliveryOrders.orderInfo.index": "Индекс",
    "reports.types": "Типы отчетов",
    "customsOfCargo.fields.price": "Стоимость партии",
    "crud.field.documentDeadline.title": "Срок подачи документов",
    "partner.userInfo.form.email.placeholder": "Введите Email",
    "crudForm.field.id.placeholder": "Не заполняется",
    "person.calculationInfo.fail": "Расчетов не найдено.",
    "common.documents.position": "Должность",
    "crud.field.actions.title": "Действия",
    "reports.lineChart.countOfAllClient": "Всего",
    "report.unit.in": "in",
    "crudForm.field.fromAirport": "Из/в аэропорт",
    "wizard.cargo.form.size.length": "Длина",
    "crud.field.geoObjects.title": "Гео объекты",
    "operatorAdmin.directories.vehicleDisclaimers": "Условия по автоперевозкам",
    "indicator.pointOfDestination.arrival.fact": "Прибытие в пункт назначения (факт)",
    "modal.contactUs.successBody": "Спасибо, мы свяжемся с Вами в ближайшее время",
    "orderForm.modal.stateTruckNumber": "Гос номер грузовика:",
    "crudForm.rates.title": "Добавить/изменить тариф",
    "partner.deliveryForClients": "Заказы",
    "crud.field.palletType.title": "Тип паллета",
    "register.title": "Регистрация",
    "form.field.commissionCurrency": "Валюта вознаграждения",
    "form.field.organization.factAddress.placeholder": "Введите фактический адрес",
    "detailsOfCalculation.orderTable.typeOfCargo": "Тип груза",
    "form.field.customsClearance": "Таможенное оформление",
    "crud.uploadFromExcel.example.body.text": "Все столбцы формы должны быть заполнены!",
    "userRegistration.passwordDontMatch": "Введенные пароли не совпадают",
    "common.invalidRange": "Неверные граница диапазона",
    "crudForm.field.common.documents.type": "Тип",
    "orderForm.actTransitTime": "Время в пути, факт:",
    "orderForm.numberOfPositions": "Позиций:",
    "form.order.truckType": "Тип подвижного состава",
    "subscription.history.text": "История подписок: ",
    "crudForm.field.currency": "Валюта",
    "orderForm.modal.driver": "Водитель:",
    "crudList.empty": "Список пуст",
    "dialog.confirm.title": "Вопрос",
    "crud.field.dateRange.title": "Срок действия",
    "indicator.pointOfOrigin.arrival.fact": "Дата прибытия в хаб (факт)",
    "common.roleClient": "Пользователь",
    "agreement.agreeMsg": "Вы подтвердили, что ознакомлены со всеми пунктами настоящего Соглашения и принимаете их",
    "orderForm.modal.cargoParameters": "Параметры груза",
    "constants.placeTypes.railwayHub": "Ж/д станция",
    "logistBlock.arrivalOrInnings": "Прибытие / подача",
    "orderForm.modal.truckNumber": "Номер грузовика",
    "indicator.pointOfArrival.departure.plan": "Дата отправления из хаба (план)",
    "orderForm.companyName": "Компания",
    "orderList.StatusColumnTitle": "Статус",
    "orderForm.warning.message": "Ваш заказ создан! Необходимо заполнить данные о грузоотправителе, грузополучателе и извещаемой стороне. Для этого вы можете воспользоваться ссылками ниже:",
    "crud.field.sellerRewardMax.title": "Макс. вознаграждение продавца",
    "crudForm.bulkOperations.nameCode.changeOwner": "Изменить владельца",
    "crud.field.error.seaPort.freightTariffExists": "Невозможно удалить порт, для которой существуют тарифы фрахта",
    "common.documents": "Документы",
    "crud.field.sort.title": "Порядок",
    "modal.save.calculation.title": "Сохранить расчет",
    "detailsOfCalculation.OrderPopover": "Напоминаем, что мы работаем только с юридическими лицами и ИП. Личные вещи не перевозим.",
    "report.transportation.avia": "авиаперевозка",
    "form.wizard.title.from": "Откуда",
    "logistBlock.invoicePaymentDate": "Дата оплаты счета",
    "crud.field.cityId.title": "Город",
    "site.slogan": "Вот что я везу!",
    "operatorAdmin.directories.seaPortHubs": "Морские порты",
    "report.place.pieces": "шт",
    "login.exit": "Войти",
    "form.wizard.pickup": "Пункт отправления",
    "crud.form.view.template.title": "Просмотр шаблона",
    "modal.orderInfo.cargo": "Груз",
    "report.unit.ft": "ft",
    "wizard.cargo.form.containerTypes": "Тип контейнера",
    "contacts.consignee": "Грузополучатель",
    "form.order.remarks": "Особые замечания",
    "deliveryOrders.listItem.amount": "Кол-во",
    "constants.month.may": "Май",
    "partner.button.save": "Сохранить изменения",
    "common.field.quotes": "расчетов",
    "detailsOfCalculation.fields.totalCost": "Общая стоимость",
    "indicator.customsClearance.departure.plan": "Таможенное оформление, отправление (план)",
    "crudForm.field.customsClearance": "Таможенное оформление",
    "orderForm.userName": "ФИО",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.currency.not.found": "Отсутствует валюта в системе. Строка {0}, столбец {1}.",
    "crudForm.field.clearing": "Очистка",
    "detailsOfCalculation.dayMn": "дней",
    "operatorAdmin.directories.services": "Услуги",
    "orderForm.numberOfPlaces": "Мест:",
    "crud.field.transitHub.title": "Транзит через",
    "crudForm.dictionary.field.mainHatchSize": "Размер грузового люка",
    "operatorAdmin.directories.consolidators": "Консолидаторы",
    "orderForm.from": "Пункт отправления:",
    "error.inn.size.10": "Введите корректный ИНН (10 цифр)",
    "operatorAdmin.countryCategories": "Категории стран",
    "client.successCreate": "Клиент создан",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.date.from": "Неверная дата начала действия тарифа. Строка {0}, столбец {1}.",
    "trunking.form.errorsTitle": "Проблемы при загрузке тарифов:",
    "reports.field.btn.download": "Скачать",
    "crudForm.dictionary.field.maxWeight.placeholder": "Макс. вес",
    "form.order.grossWeight": "Вес брутто",
    "constants.commissionTypes.priceRange": "Диапазон цен",
    "crudForm.field.intraportForwarding.container.to": "Контейнеров до",
    "detailsOfCalculation.weight": "вес",
    "client.passwordChanged": "Пароль изменен",
    "form.field.notificationOnTariffChange": "Подписка на изменение тарифов",
    "crud.field.organization.name.title.my": "Моя организация",
    "partner.bankDetails.form.field.numberAccount": "Номер расчетного счета",
    "trunking.form.downloadBtn": "Скачать",
    "crudForm.field.required": "Обязательно",
    "common.invalidEmail": "Некорректный email адрес",
    "modal.orderInfo.template": "Шаблон",
    "crud.field.airBillRate": "Цена за AWB",
    "customsOfCargo.fields.totalCost": "Итого",
    "operatorAdmin.reports": "Статистика по расчетам",
    "reports.lineChart.countOfNew": "Новые",
    "partner.bankDetails.form.field.corrAccount.placeholder": "Введите номер корр. счёта",
    "detailsOfCalculation.callManager.modalFailBody": "Не удалось отправить сообщение. Пожалуйста, повторите попытку позже.",
    "detailsOfCalculation.dayImP": "день",
    "reports.lineChart.countOfAll": "Все",
    "crudForm.field.countries": "Страны",
    "page.accessDenied.text": "У Вас нет доступа к странице",
    "detailsOfCalculation.customsHouseBtn": "Таможенный калькулятор",
    "user.passwordRestored": "Пароль успешно восстановлен",
    "crudForm.dictionary.field.maxCommercialDownload": "Макс. коммерческая загрузка, (кг)",
    "report.transportation.express": "ускоренная перевозка",
    "crud.field.passwordReset.label": "Сброс пароля",
    "operatorAdmin.listOfAPIs.connect": "Подключить",
    "partner.rates.roadTransportation": "Автоперевозка",
    "wizard.cargo.form.size.width.placeholder": "Ш",
    "partner.orders": "Заказы",
    "report.cargo.general": "Сборный груз",
    "crud.filter.modifier": "Тип",
    "general.oversized.cargo": "Негабаритный груз. Попробуйте изменить параметры груза или свяжитесь с нами для уточнения параметров перевозки.",
    "orderForm.shipper": "Грузоотправитель:",
    "crud.field.considerFailedRequests.title": "Учитывать ответы без результата",
    "crud.field.railwayStation.title": "Ж/д станция",
    "crud.field.ratingReportBtn.title": "Посмотреть отчет",
    "palletTariff.form.uploadDesc": "Файл должен быть в формате .xlsx",
    "crudForm.field.ADR_RAILWAY": "Адрес ⇄ Ж/д Станция",
    "detailsOfCalculation.filters.sort.cost": "Цена",
    "crud.field.demurrage.title": "Демередж",
    "deliveryOrders.orderList.searchPlaceholder": "Введите номер заказа или референс груза",
    "deliveryOrders.listItem.orderDate": "Дата заказа",
    "form.field.companyName.placeholder": "Название компании",
    "operatorAdmin.directories.typesOfGoods": "Контейнеры",
    "detailsOfCalculation.fields.commission": "Вознаграждение",
    "wizard.cargo.form.consolidatedCargo.add": "Добавить",
    "form.field.commission.sea_transportation.menu": "Морская перевозка",
    "operatorAdmin.directories.hubs": "Хабы",
    "form.field.organizationName.placeholder": "Введите наименование организации",
    "crud.field.priceFirst.title": "Цена за первую единицу груза.",
    "crudForm.field.conversionPercent": "Процент конвертации",
    "crud.filter.to": "До",
    "HOME_RAILWAY_WITHOUT_SYMBOL": "Домашняя Ж/Д накладная",
    "report.unit.сm": "cм",
    "crudForm.field.tariffStatus": "Статус",
    "notifications.noNotifications": "Уведомлений пока нет.",
    "subscription.page.config.title": "Конфигурация системы подписок",
    "сommon.copy.error": "Не удалось скопировать данные",
    "reportResource.filter.period": "Период отчета",
    "site.footer.menu.personalArea": "Личный кабинет",
    "wizard.cargo.form.palletTypes.placeholder": "Паллет",
    "crud.field.billing.absolute": "Абсолютная",
    "crud.field.categoryId.title": "Категория",
    "crud.uploadFromExcel.example.body.btn.download": "Скачать",
    "importTranslates.alertNotSelectTranslation": "Выберете минимум один столбец с переводом",
    "operatorAdmin.deliveryForClients": "Заказы",
    "form.field.notificationLimitScan": "Внимание! При изменении периода сбора статистики популярных направлений, изменения вступят в силу в течении 24 часов.",
    "partner.commission.empty": "Вознаграждение для выбранных профилей отсутствует",
    "person.detailsOfCalculation.fail": "Не удалось получить данные заказа",
    "report.transportation.complex": "комплексная перевозка",
    "constants.modifier.private": "Приватный",
    "crud.field.error.railwayOperator.railwayTariffExists": "Невозможно удалить железнодорожного оператора, для которой существуют тарифы на ж/д перевозку",
    "constants.insuranceTransportType.sea": "Морская",
    "crudForm.bulkOperations.profile.error": "У организации отсутствует необходимый профиль",
    "common.required.privateRanges": "Необходимо добавить 1 приватный диапазон",
    "crudForm.button.cancel": "Отмена",
    "crudForm.field.portsOfDeparture": "Порты отправления",
    "form.field.maxKM": "Макс. расстояние",
    "constants.month.november": "Ноябрь",
    "detailsOfCalculation.fields.showTariffDetails": "Показать детали",
    "wizard.tooltip.use40Container": "Для ваших параметров груза рассмотрите заказ перевозки контейнером 40'.",
    "common.documents.actionsTitle": "Действия",
    "partner.profile": "Профиль",
    "form.field.password.placeholder": "Введите пароль",
    "directionOfTransportation.departure": "Отправление",
    "wizard.subscribe.message.first": "Хотите быть всегда в курсе актуальных ставок и обновленных тарифов на грузоперевозку? Подпишитесь на информационную рассылку Agorafreight.com.",
    "deliveryOrders.cargoInformation.cargoTypeTitle": "Информация о грузе",
    "crud.field.countVariantsExternalCalculation.title": "Количество вариантов расчетов с учетом внешних партнеров",
    "detailsOfCalculation.filters.sort.time": "Время доставки",
    "crudForm.field.customsPost": "Таможенный пункт",
    "form.order.netWeight": "Вес нетто",
    "crudForm.field.maxHeight": "Максимальная высота",
    "crudForm.field.maxWeight": "Максимальный вес",
    "constants.charging.PORT_RAILWAY": "Порт ⇄ Ж/д Станция",
    "reports.types.ORGANIZATION_COUNT": "Количество организаций с тарифами",
    "crudForm.field.toHub": "Куда",
    "auth.success.message": "Авторизация прошла успешно.",
    "detailsOfCalculation.filters.sort.maxNumber": "Максимальное число",
    "mainMenu.subscriptions": "Подписки",
    "crud.field.error.organization.orderRequestExists": "Невозможно удалить организацию, у которой есть открытые заказы.",
    "crud.field.name.title": "Название",
    "site.leftMenu.link.contactUs": "Связаться с нами",
    "crudForm.dictionary.currencies.title": "Валюта",
    "crud.field.order.id.title": "Номер расчета",
    "form.field.status": "Статус",
    "form.field.private": "Обработка данных",
    "common.documents.sendEmailActionTitle": "отправить на почту",
    "crud.field.generalCargoRangeRate.title": "Диапазон цен",
    "crudForm.field.nds.ndsInc": "Включен в тариф",
    "form.order.palletsNumber": "Количество поддонов",
    "detailsOfCalculation.filters.totalCount": "Результатов",
    "site.footer.subscribe.placeholder": "E-mail",
    "HOME_AWB": "Домашняя авианакладная:",
    "operatorAdmin.directories.seaLines": "Морские линии",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.port.to.not.found": "Отсутствует порт прибытия в системе. Строка {0}, столбец {1}.",
    "modal.changePassword.buttonSave": "Сохранить",
    "deliveryOrders.cargoTracking.table.conversion": "Конвертация",
    "user.invalidOldPassword": "Неверный старый пароль",
    "crud.field.cargoContainerRates.title": "Тип контейнера и цены",
    "person.calculationInfo.tryWithOtherData": "Расчетов не найдено.{br}Пока платформа работает в тестовом режиме с ограниченной функциональностью только по направлению Китай - РФ.{br}Вы можете отправить нам сообщение и очень оперативно получить индивидуальный расчет.",
    "crudForm.field.period.on.15d": "На 15 дней",
    "partner.bankDetails.form.field.address.placeholder": "Введите юридический адрес",
    "deliveryOrders.cargoInformation.cargoTypeBox": "Коробки",
    "orderForm.modal.comment": "Комментарий",
    "importTranslates.titleResult": "Результат импорта переводов",
    "crud.disclaimers.vehicle.cargoType": "тип груза",
    "form.field.container": "Контейнеры",
    "wizard.cargo.form.amount": "Количество (шт)",
    "form.field.address.to.placeholder": "Куда (адрес или индекс) ...",
    "crud.filter.expiredTariffsParam": "Истекшие тарифы",
    "modal.contactUs.sendBtn": "Отправить",
    "detailsOfCalculation.logIn": "авторизоваться",
    "partner.geography.form.address": "Адрес",
    "crudForm.field.minTransitPriceCost": "Мин. цена транзита",
    "customsOfCargo.rate.advalorem": "Таможенная пошлина взимается по ставке {mainRate}% от стоимости товара. НДС {nds}%",
    "modal.orderInfo.shippingDate": "Дата отправки",
    "form.field.middleName": "Отчество",
    "login.submit.vk": "Войти через ВКонтакте",
    "crudForm.dictionary.field.floorSize": "Площадь пола, (м кв.)",
    "detailsOfCalculation.tariffOrganization": "Организация",
    "modal.tracking.btn": "Отслеживание груза",
    "form.field.countryTo": "Страна (куда)",
    "reports.field.btn.downloadCsv": "скачать как .csv",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.detension": "Неверно указано detension время. Строка {0}, столбец {1}.",
    "form.select.noResultText": "Ничего не найдено",
    "subscription.tariff.create.request.confirm.text": "Ваш запрос будет рассмотрен в ближайшее время.",
    "crud.field.arrivalData.title": "Грузополучатель",
    "form.field.numberAccount": "Номер счёта",
    "crud.field.hasCustoms.title": "Таможенное оформление",
    "subscription.modal.add.btn": "Оформить",
    "crud.filter.field": "Поле",
    "reports.barChart.TRANSPORT_HUB": "Тр. хабы",
    "deliveryOrders.status.ARRIVED_TO_DESTINATION_HUB": "Прибыл в HUB назначения",
    "deliveryOrders.status.ARCHIVED": "В архиве",
    "indicator.pointOfArrival.departure": "Дата отправления из хаба назначения",
    "operatorAdmin.charts": "Статистика по расчетам",
    "widget.order.getintouch": "Как можно скорее с Вами свяжется персональный менеджер и все организует.",
    "crudForm.field.stations": "Станции",
    "agreement.modal.btnAgree": "Принимаю",
    "crud.field.commission.title": "Вознаграждение",
    "form.order.shipper": "Отправитель",
    "report.transportation.sea": "морская перевозка",
    "wizard.button.reset": "Сбросить",
    "detailsOfCalculation.successOrder.body": "Благодарим за заказ! Номер вашего заказа - {orderId}.{br}Наш специалист свяжется с вами как можно скорее. Как правило, в рабочее время это происходит в течение 15 минут.",
    "subscription.page.title": "Моя подписка",
    "crud.filter.no": "Нет",
    "importTranslates.name.code": "Код",
    "modal.changePassword.password.placeholder": "Введите новый пароль",
    "notifications.type.ExpiredCalculation.link.title": "Подробнее",
    "crudForm.field.customsClearance.placeholder": "Введите цену",
    "indicator.pointOfPickup.departure.plan": "Дата отправления (план) ",
    "detailsOfCalculation.transitTime": "Расчетное время в пути",
    "detailsOfCalculation.callManager.checkPriceBtn": "Уточнить цену",
    "detailsOfCalculation.discussionCalc": "Обсудить расчет",
    "crudForm.dictionary.field.code_currency.placeholder": "Введите код валюты",
    "form.field.lastName": "Фамилия",
    "crud.field.country.to.title": "Страна куда",
    "modal.tracking.title": "Отслеживание груза",
    "subscriptionRequest.title": "Заявки клиентов на подписку",
    "TTH_WITHOUT_SYMBOL": "TTH",
    "crud.field.cargo.title": "Груз",
    "crudForm.field.period.on.12m": "На год",
    "constants.nds.none": "Без НДС",
    "detailsOfCalculation.cargoIsOverSize.bot": "Направьте запрос в один клик, и мы оперативно рассчитаем стоимость для Вас индивидуально.",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.sealine.empty": "Неверный код морской линии. Строка {0}, столбец {1}.",
    "detailsOfCalculation.updateCalculation": "Пересчитать",
    "register.partner.title": "Зарегистрироваться как партнер",
    "crudForm.field.fromHub": "Откуда",
    "operatorAdmin.directories.ports": "Порты",
    "crud.field.ON_FREIGHT": "На фрахт",
    "form.field.confirmPassword.placeholder": "Повторите пароль",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.currency.empty": "Неверно заполнена валюта. Строка {0}, столбец {1}.",
    "detailsOfCalculation.createDateNew": "от",
    "common.equalsroad": "Выбраны одинаковые пути",
    "crudForm.field.sum": "Сумма",
    "deliveryOrders.cargoInformation.cargoTypePackage": "Палеты",
    "crud.field.forwardRates.title": "Диапазон цен",
    "organizationsProfiles.title": "Профили организаций",
    "reportResource.report.avg.countVariants": "Среднее количество вариантов расчетов",
    "crud.field.number": "Номер",
    "form.field.periodStart": "Период (начало)",
    "crud.add.record": "Добавить",
    "partner.generalInformation.form.field.profileStatus.REJECTED": "Отклонен",
    "crudForm.field.intraportForwarding.container.price": "За контейнер",
    "crud.disclaimers.vehicle.hubName": "хаб",
    "detailsOfCalculation.fields.general.air": "Авиагруз",
    "partner.geography.geoTypes.city": "Город",
    "subscription.page.config.resetFree.value.DAY": "День",
    "detailsOfCalculation.successOrder.title": "Заказ сформирован",
    "indicator.customsClearance.arrival": "Таможенное оформление, прибытие",
    "detailsOfCalculation.priceOverTime.client": "Расчет включает {minHour}-часовую перевозку, далее оплата почасовая согласно тарифа",
    "common.error": "Ошибка",
    "orderForm.currentStatus": "Текущий статус груза:",
    "crud.field.dateTo.title": "Срок действия до",
    "detailsOfCalculation.fields.cost": "Стоимость",
    "operatorAdmin.directories.vehicleTypes": "Типы ТС",
    "detailsOfCalculation.makeOrderConfirmation": "Вы уверены, что хотите сделать заказ?",
    "detailsOfCalculation.filters.success.text": "Данные успешно отсортированы",
    "mainMenu.reportResource": "Отчет использования ресурсов",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.freightTerms.wrong": "Неверное значение условия перевозки. Строка {0}, столбец {1}.",
    "form.order.address": "Адрес",
    "report.transportation.multimodal.sea": "мультимодальная перевозка (море + авто)",
    "tracking.fields.date": "Дата",
    "detailsOfCalculation.hourImP": "час",
    "partner.userInfo.form.middleName": "Отчество",
    "crudForm.bulkOperations.success": "Записей изменено",
    "wizard.general.weight": "Вес",
    "crud.field.service": "Услуга",
    "auth.alreadySuccess.message": "Вы уже авторизованы.",
    "crud.field.destinationAirPort.title": "Аэропорт назначения",
    "form.order.internalSystemOrderCode": "Внутринний код Заказа",
    "detailsOfCalculation.calcIDNew": "Расчет №",
    "detailsOfCalculation.fields.conversionPercent2": "тация",
    "operatorAdmin.partners": "Партнеры",
    "detailsOfCalculation.fields.conversionPercent1": "Конвер-",
    "indicator.deliveryToRecipientDate": "Дата доставки получателю",
    "common.documents.fileName": "Имя файла",
    "crud.field.id.title": "№",
    "ordersList.now": "Сейчас:",
    "form.field.includeInInsurance": "Включить в страховку",
    "form.wizard.title.insurance": "Страхование груза",
    "reports.barChart.SEA_LINE": "Морские линии",
    "crudForm.field.railwayOperator": "Жд оператор",
    "form.field.address.from.placeholder": "Откуда (адрес или индекс) ...",
    "crud.field.organization.phone.title": "Тел. Организации",
    "LogisticDocType.PACKING_LIST": "Упаковочный лист",
    "deliveryOrders.requestId": "Номер подзаказа",
    "trunking.form.uploadTitle": "Загрузка тарифов",
    "operatorAdmin.directories.templateDescriptionTariff": "Комментарии к тарифам",
    "detailsOfCalculation.customs.desc": "Описание",
    "crud.field.active.no": "Не действует",
    "deliveryOrders.orderList.status.notDelivered": "не доставлено",
    "CMR": "Автодорожная накладная:",
    "common.download": "Скачать",
    "constants.tarifications.absolute": "Абсолютная",
    "HOME_RAILWAY": "Домашняя Ж/Д накладная:",
    "detailsOfCalculation.pieces": "шт.",
    "detailsOfCalculation.orderInfo.submit": "Внести данные",
    "crud.field.shippingDate.title": "Планируемая дата готовности",
    "form.select.loading": "Загрузка...",
    "customsOfCargo.fields.weight": "Вес",
    "wizard.cargo.customsOfCargo": "Место таможенного оформления",
    "confirm.order.fail": "Ошибка в параметрах расчета",
    "orderForm.commentPrice": "Комментарии к цене:",
    "detailsOfCalculation.filters.currency": "Валюта",
    "form.field.commission.integrator.percent.min": "не менее",
    "subscription.manage.tariffs.section": "Управление подпиской",
    "crud.field.departurePort.title": "Порт отправления",
    "constants.typeOfRoadGeneral.wm": "с учетом w/m",
    "crudForm.dictionary.btn.removeHatch": "Удалить отсек",
    "form.order.shippingDateFact": "Дата готовности груза, факт",
    "order.createNewManual.detailsOrder.changeOwnerOrg.title": "Организация заказчика перевозки",
    "agreement.modal.title": "Условия использования",
    "detailsOfCalculation.callManager.modalSendBtn": "Отправить",
    "trunking.form.counterSecondString": "из",
    "subscription.main.dateCreated.text": "Дата оформления: ",
    "constants.placeTypes.transportHub": "Транспортный хаб",
    "indicator.customsClearance.arrival.fact": "Таможенное оформление, прибытие (факт)",
    "crud.field.comment.title": "Комментарий",
    "service.serverError": "Ошибка сервера",
    "crud.field.organizationName.title": "Название организации",
    "deliveryOrders.delivery": "Доставка",
    "detailsOfCalculation.insurance.cargoCost": "Стоимость товара",
    "crudForm.field.surcharges": "Надбавки",
    "crud.field.error.organization.usersExists": "Невозможно удалить организацию, у которой есть пользователи",
    "trunking.form.noLoadedFile": "Нет загруженных файлов",
    "detailsOfCalculation.customs.title": "Таможенное оформление",
    "wizard.subscribe.cancelBtnText": "Нет, спасибо",
    "partner.bankDetails.form.field.ogrn": "ОГРН",
    "constants.typeOfRoadGeneral.radius": "с учётом радиусов",
    "register.submit": "Готово",
    "site.leftMenu.link.news": "Новости",
    "constants.placeTypes.seaPort": "Морской порт",
    "crud.field.transportationType.title": "Тип перевозки",
    "crud.field.orderNumber": "Номер заказа",
    "reports.statusTest.emptyAllReports": "Необходимо сгенерировать отчеты",
    "form.field.commissionPublic": "Общедоступная",
    "constants.quarter": "Квартал",
    "common.documents.orderId": "Номер заказа",
    "orderForm.estTransitTime": "Время в пути, план:",
    "mainMenu.subscriptions.userSubscription": "Подписки клиентов",
    "form.order.common": "Общее",
    "crud.field.size.title": "Размеры (L, W, H)",
    "site.footer.copyright": "© {thisYear} <a href='https://cargobar.ru/' target='_blank'>ООО «КАРГОБАР»</a>. Все права защищены",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.transitTime": "Неверно указано транзитное время. Строка {0}, столбец {1}.",
    "crud.field.GENERAL_CARGO": "Сборный груз",
    "modal.save.calculation.checkbox.title": "Сохранить для клиента",
    "deliveryOrders.cargoInformation.cargoWeightTitle": "Вес",
    "crud.field.freightCondition.title": "Условия фрахта",
    "detailsOfCalculation.documentDeadline": "Срок подачи документов",
    "detailsOfCalculation.outdatedCalculationMsg": "Данный расчет требует обновления.",
    "crud.field.direction.title": "Направление",
    "deliveryOrders.cargoInformation.title": "Информация о грузе",
    "constants.month.august": "Август",
    "modal.orderInfo.owner": "Заказчик",
    "subscription.switchToFree.btn.yes.text": "Подтвердить",
    "form.field.position": "Должность",
    "common.status.current": "Текущий этап",
    "operatorAdmin.directories.freightSurcharges": "Надбавки фрахта",
    "tracking.fields.organization": "Организация",
    "form.field.commission.customs_road_transportation.menu": "Таможенный автоперевозчик",
    "partner.rates.railwayTransportation": "Ж/д перевозка",
    "orderForm.sidesLimit": "Добавлено максимальное количество извещаемых сторон",
    "crud.field.position.title": "Должность",
    "indicator.pointOfPickup.load": "Дата погрузки",
    "operatorAdmin.directories.airportsHubs": "Аэропорты",
    "orderForm.notificateConsignee": "Известить грузополучателя",
    "common.documents.userName": "Автор документа",
    "subscription.main.tariff.text": "Подписка: ",
    "customsOfCargo.fields.customsPrice": "Таможенный сбор",
    "commentDescription.button.create": "Создать новый",
    "user.invalidOperatorOrganization": "Неверная организация у пользователя с ролью оператор",
    "indicator.pointOfArrival.arrival.fact": "Дата прибытия в хаб (факт)",
    "wizard.find": "Найти",
    "constants.nds.currentRate": "20",
    "form.field.position.placeholder": "Введите должность",
    "deliveryOrders.orderBlock.orderActions.getNotifications": "Получать уведомления",
    "crud.field.generalCargoRangeRate.price": "цена",
    "orderForm.actPrice": "Цена, факт:",
    "site.leftMenu.link.profile": "Профиль",
    "deliveryOrders.status.CONFIRMED": "Подтвержден отправителем",
    "detailsOfCalculation.filters.sort": "Сортировка",
    "form.field.users": "Пользователи",
    "order.location.seaPort.required": "Поле обязательно к заполнению",
    "login.submit": "Войти",
    "detailsOfCalculation.services_departure": "Услуги в порту отправления",
    "wizard.tooltip.use20Container": "Для ваших параметров груза рассмотрите заказ перевозки контейнером 20'.",
    "crudForm.field.TypeOfTariff": "Тип",
    "operatorAdmin.transportationRules": "Правила перевозки",
    "detailsOfCalculation.transitTimeNew": "Время в пути",
    "dialog.confirm.default.question": "Вы уверены?",
    "crudForm.field.description": "Описание",
    "tracking.fields.statuses": "Статусы",
    "crudForm.button.apply": "Применить",
    "indicator.pointOfPickup.ready": "Дата готовности груза",
    "ratingReport.scale": "Масштаб",
    "constants.NOT_PAID": "Не оплачен",
    "deliveryOrders.listItem.weight": "Вес",
    "deliveryOrders.cargoInformation.cargoVolumeTitle": "Объем",
    "partner.rates.trunking": "Магистральная перевозка",
    "crudForm.dictionary.field.size": "Размеры, (L, W, H)",
    "crudForm.field.transportationType": "Тип перевозки",
    "wizard.cargo.form.car.title": "Машины",
    "crud.field.departureAirPort.title": "Аэропорт отправления",
    "rating.page.title": "Рейтинг пользователей",
    "reports.field.month": "Месяц",
    "deliveryOrders.cargoTracking.table.service": "Услуга",
    "crud.filter.include": "Включен в тариф",
    "constants.typeOfRoadGeneral.rc": "с учетом регионального коэффициента",
    "partner.rates.railwayServices": "Услуги на станции",
    "form.field.confirmCode": "Код подтверждения",
    "common.delete.success": "Данные удалены",
    "crud.label.yes.no": "Да/Нет",
    "orderForm.departureDate": "Дата погрузки:",
    "reports.barChart.ROAD_TRANSPORT_TARIFF": "Транспортные тарифы",
    "indicator.pointOfPickup.ready.plan": "Дата готовности груза (план)",
    "common.applyTemplate": "Заполнить из шаблона",
    "crud.field.franchise.title": "Франшиза",
    "person.delivery": "Заказы",
    "LogisticDocType.FREIGHT_INVOICE": "Счет за фрахт",
    "deliveryOrders.orderList.headerTable.cargo": "Груз",
    "crudForm.field.airTransportation.to": "До",
    "crudForm.field.pricePerKg": "Цена за килограмм",
    "form.order.incoterms": "Условия поставки",
    "operatorAdmin.directories.airlineDisclaimers": "Условия по авиаперевозкам",
    "deliveryOrders.orderList.headerTable.orderNumber": "№ Заказа",
    "login.title": "Авторизация",
    "detailsOfCalculation.fields.vatPersent": "НДС %",
    "crud.field.error.service.tariffExists": "Невозможно удалить услугу, для которой существуют тарифы",
    "circular.parent.become.child": "Нельзя использовать родительский элемент в качестве дочернего",
    "detailsOfCalculation.RenewBtn": "Обновить",
    "dialog.confirm.commission.notSaveData.question": "У вас есть не сохраненные данные. Вы уверены, что хотите перейти?",
    "operatorAdmin.directories.trucks": "Автомобили",
    "wizard.customsOfCargo.containerImP": "контейнер",
    "crud.filter.hideExpired": "Скрыть",
    "common.alertWorkJobScan": "В данный момент происходит анализ статистики популярных направлений. Результаты будут доступны после завершения анализа.",
    "detailsOfCalculation.fields.includeService": "Включить услугу",
    "form.field.station": "Выбрать станцию",
    "common.unsupported.geo.region": "Регион не входит в зону географии вашего присутствия.",
    "auth.banned.message": "Из-за нескольких неудачных попыток входа ваш аккаунт временно заблокирован. Попробуйте повторить авторизацию через 5 минут.",
    "crud.field.save.title": "Сохранить",
    "crud.uploadFromExcel.tooltip": "Все тарифы должны быть загружены с помощью нашей стандартной формы. Пожалуйста, используйте шаблон с этой страницы.",
    "crud.add.newOrder": "Новый расчет",
    "profile.organizationTemplates": "Шаблоны организаций",
    "form.field.periodEnd": "Период (конец)",
    "detailsOfCalculation.to": "до",
    "crudForm.field.billRate": "Цена за B/L",
    "customsOfCargo.rate.specific": "Таможенная пошлина взимается по ставке {value} {currency} за 1 {unit}. НДС {nds}%",
    "form.field.commission.integrator.percent.max": "не более",
    "person.button.edit": "Редактировать",
    "crud.field.address.title": "Адрес",
    "subscription.main.datePayNext.text": "Дата следующего платежа: ",
    "form.field.commissionPrivate": "Приватная",
    "deliveryOrders.cargoTracking.table.cost": "Стоимость",
    "form.order.cargoName": "Наименование груза",
    "crud.field.error.railway.roadTariffExists": "Невозможно удалить железнодорожную станцию, для которой существуют тарифы на автоперевозку",
    "operatorAdmin.directories.currencies": "Валюты",
    "radius.prices": "Цены",
    "crud.field.error.seaPort.portTariffExists": "Невозможно удалить порт, для которой существуют тарифы в порту",
    "crud.field.error.consolidator.freightTariffExists": "Невозможно удалить консолидатора, для которого существуют тарифы фрахта",
    "person.button.save": "Сохранить",
    "form.order.bill": "Инструкции по заполнению коносамента",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.port.from.not.found": "Отсутствует порт отправления в системе. Строка {0}, столбец {1}.",
    "reports.lineChart.countOfClient": "Клиентов",
    "deliveryOrders.status.PLANNING": "Планируется",
    "partner.generalInformation.form.field.factAddress.placeholder": "Введите почтовый адрес",
    "crud.remove.request.label": "Вы уверены, что хотите отменить заявку на подписку?",
    "crudForm.field.costForWM": "Цена за w/m",
    "confirm.help.statusNotConfirm": "Дорогой пользователь! Мы заметили, что мы уже высылали код подтверждения регистрации на ваш email. Проверьте, пожалуйста, вашу почту, в том числе папку спам. Мы отправили повторный код подтверждения для завершения регистрации.",
    "page.main": "Главная",
    "form.order.companyName": "Компания",
    "customsOfCargo.rate.max_of_both": "Таможенная пошлина взимается по ставке {mainRate}% от стоимости товара, но не менее {value} {currency}/{unit}. НДС {nds}%",
    "crud.field.surchargeText": "надбавка",
    "partner.userInfo.form.lastName": "Фамилия",
    "detailsOfCalculation.fields.showTariffDetailsNew": "Подробнее",
    "crud.field.cargoTypeName.title": "Тип груза",
    "operatorAdmin.directories.railwayHubs": "Железнодорожные станции",
    "form.order.companyAddress": "Адрес",
    "wizard.cargo.unit": "Элементов",
    "partner.rates.public": "Загрузка из excel",
    "wizard.directionForm.addressFrom": "Город",
    "logisticBlocks.inExcel": "Отчет в Excel",
    "detailsOfCalculation.tryEnd": "и повторить расчет.",
    "crudForm.field.perKm": "За км",
    "wizard.cargo.form.amount.placeholder": "Введите количество",
    "notifications.read": "Отметить прочитанным",
    "subscription.tariff.create.request.confirm": "Заявка на подписку оформлена.",
    "crud.field.usageLimit.crud.title": "Расчеты (использовано/всего)",
    "organizationsProfiles.deleteProfileConfirm": "Вы действительно хотите удалить профиль из организации?",
    "crud.field.error.railwayOperator.railwayStationTariffExists": "Невозможно удалить железнодорожного оператора, для которой существуют тарифы на услуги на станции",
    "detailsOfCalculation.insurance.interestType": "Вид товара",
    "crud.save.success": "Элемент успешно сохранен.",
    "crudForm.field.transitCargo.customCleared": "Груз растаможен",
    "form.order.date": "Дата",
    "excel.tariff.no.directionTariffType": "Не указано направление из города или в город",
    "crud.field.arrivalPlace.button.add": "Добавить данные грузополучателя",
    "wizard.cargo.form.weightTo": "До",
    "wizard.cargo.form.vehicleBody": "Тип кузова",
    "crud.field.country.name.title": "Страна",
    "trunking.form.defaultFileName": "Тариф на магистральную перевозку",
    "common.documents.downloadActionTitle": "Скачать",
    "page.expiredTariffs.railway-services": "Услуги станции",
    "common.errorNullTemplate": "Шаблон не может быть пустым! Заполните данные грузоотправителя и/или грузополучателя.",
    "form.field.commission.container.to": "Контейнеров до",
    "crudForm.field.airTransportation.from": "От",
    "reports.types.POPULAR_DESTINATIONS_TREND": "Популярные направления (по отношению к предыдущему периоду)",
    "deliveryOrders.cargoTracking.table.total": "Итого",
    "crud.pagination.title": "Всего найдено",
    "deliveryOrders.cargoTracking.table.taxName": "НДС",
    "crud.field.auditEventType.title": "Тип события аудита",
    "confirm.help.status": "Мы отправили код подтверждения на ваш email. Пожалуйста, проверьте все папки, в том числе и спам.",
    "crud.field.paid.title": "Платное API",
    "crud.field.createDate.title": "Дата создания",
    "register.partner.description": "Описание системы для партнера",
    "organizationsProfiles.rejectProfileConfirm": "Вы действительно хотите отклонить профиль?",
    "partner.bankDetails.form.field.inn": "ИНН",
    "common.unique": "Значение должно быть уникальным",
    "trunking.form.addFile": "Прикрепить файл",
    "LogisticDocType.HOME_BILL": "Домашний коносамент/авианакладная",
    "constants.vehicleDistanceRange.UNDER_100": "<100 км",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.freightTerms.empty": "Отсутствует условие перевозки. Строка {0}, столбец {1}.",
    "crudForm.field.service": "Услуга",
    "detailsOfCalculation.callManager.checkTransitBtn": "Направить запрос",
    "crud.filter.title": "Фильтр",
    "wizard.cargo.form.size.height": "Высота",
    "importTranslates.resultCountAll": "Всего импортировано:",
    "crud.filter.from": "От",
    "modal.orderInfo.status": "Статус",
    "crud.field.additions.title": "Надбавки",
    "reports.types.TARIFF_COUNT": "Количество действующих тарифов",
    "wizard.subscribe.message.mobile": "Подпишитесь на информационную рассылку Agorafreight.com: не более 2 писем в месяц, только актуальная информация.",
    "trunking.form.uploadError": "Ошибка загрузки файла",
    "crud.filter.cargoContainerType": "Тип контейнера",
    "customsOfCargo.fields.price.placeholder": "Стоимость",
    "wizard.cargo.form.weight": "Вес",
    "widget.order.thanks": "Благодарим за заявку!",
    "detailsOfCalculation.filters.updated.text": "Данные успешно обновлены",
    "crudForm.field.consolidation": "Консолидация",
    "detailsOfCalculation.fields.vat": "НДС",
    "crud.field.regionId.title": "Страна",
    "crudForm.bulkOperations.nameCode.prolong": "Продлить",
    "form.field.airport": "Выбрать аэропорт",
    "partner.services.serviceTypes.serviceProfiles": "Профили услуг",
    "form.order.zipCode": "Индекс",
    "operatorAdmin.users": "Пользователи",
    "indicator.receiptOfDocuments.fact": "Дата получения документов (факт)",
    "detailsOfCalculation.customs.price": "Стоимость",
    "site.leftMenu.link.termsOfUse": "Условия использования",
    "form.order.personName": "Имя",
    "detailsOfCalculation.changeParams": "изменить параметры",
    "form.wizard.title": "Рассчитать перевозку онлайн",
    "crud.field.railwayOperator.title": "Жд оператор",
    "deliveryOrders.orderList.searchBtnTitle": "Поиск",
    "crudForm.field.containerType": "Тип",
    "form.button.back": "Назад",
    "crud.field.language.title": "Язык пользователя",
    "partner.bankDetails.form.field.numberAccount.title": "Расчетный счет",
    "reportResource.report.avg.countInternalVariants": "Среднее количество вариантов расчетов без внешних партнеров",
    "crud.field.error.seaLine.surchargeExists": "Невозможно удалить морскую линию, для котрой существует надбавка фрахта",
    "crud.field.RELATIVE": "Относительная",
    "partner.userInfo.form.phone.placeholder": "Введите телефон",
    "crud.filter.show": "Показать",
    "crud.field.error.seaPort.roadTariffExists": "Невозможно удалить транспортный хаб, для которого существуют тарифы на автоперевозку",
    "constants.month.march": "Март",
    "subscription.request.title": "История заявок",
    "crudForm.bulkOperations.form.field.name": "Ф.И.О.",
    "wizard.tooltip.selectContainerOther": "Выбрать контейнер.",
    "crudForm.field.betweenTheCityPerDistance": "Между городами (за км)",
    "notifications.delete": "Удалить",
    "crud.field.locked.title": "Заблокирован",
    "crudForm.field.documentDeadline.placeholder": "Срок подачи документов, (дн)",
    "crud.field.city.name.title": "Город",
    "crud.field.countVariantsInternalCalculation.title": "Количество вариантов расчетов без внешних партнеров",
    "crud.field.cargoKind.title": "Тип ёмкости",
    "crud.field.region.title": "Регион",
    "deliveryOrders.listItem.size": "Габариты",
    "form.field.limit": "Лимит",
    "form.order.arrival": "Место прибытия",
    "crud.edit.bulkOperation": "Изменить выделенное",
    "crudForm.field.demurrage": "Демередж, (дн)",
    "crud.field.error.profile.cantRemove": "Невозможно удалить профиль, к которому привязаны данные организации",
    "register.client.description": "Описание системы для клиента",
    "common.send": "Отправить",
    "ordersList.left": "Осталось:",
    "crud.field.seaLine.title": "Линия",
    "crudForm.field.typeOfCargo": "Тип груза",
    "wizard.customsOfCargo.title": "Таможенное оформление",
    "disclaimers.calculation.label": "Дополнительные условия перевозки",
    "operatorAdmin.directories.events": "События",
    "reports.field.count": "Количество",
    "excel.tariff.no.organization": "Не удалось найти организацию",
    "operatorAdmin.emptyOrders": "Расчеты без результатов",
    "crud.field.countryFrom.title": "Откуда",
    "crud.field.routeType.title": "Направление",
    "error.bik.size.9": "Введите корректный БИК (9 цифр)",
    "crudForm.field.view": "Просмотр",
    "orderForm.cargoVolume": "Общий объем:",
    "detailsOfCalculation.fields.tariff": "Тариф",
    "operatorAdmin.listOfAPIs.disconnect": "Отключить",
    "crud.field.cargoTypeCalculation.title": "Количество расчетов (по типам грузов)",
    "wizard.cargo.form.size.length.placeholder": "Д",
    "form.order.destination": "Место отправления",
    "crudForm.field.common.documents.nameOfDocument": "Название документа",
    "detailsOfCalculation.fields.seaLine": "Морская линия",
    "operatorAdmin.directories.template": "Шаблоны для заполнения заказов",
    "partner.bankDetails.form.field.inn.placeholder": "Введите ИНН",
    "warning.FeelDataReal": "Для получения ответа заполните обязательные поля только реальными данными",
    "OF_DESTINATION": "назначения",
    "common.documents.numberOfDocument": "Номер документа",
    "partner.salesRules": "Правила продаж",
    "crud.field.error.railwayOperator.roadTransportTariffExists": "Невозможно удалить железнодорожного оператора,для которой существуют тарифы на автоперевозку",
    "crud.field.toHub.title": "Куда",
    "crud.field.error.profile.categoryExists": "Невозможно удалить профиль, к которому привязаны категории услуг",
    "crudForm.field.minWeight": "Мин. вес",
    "crudForm.field.transitCargo.inTransit": "Груз в транзите",
    "detailsOfCalculation.hideDetails": "Скрыть",
    "form.field.email": "Email",
    "mainMenu.mySubscription": "Моя подписка",
    "detailsOfCalculation.orderTable.volume": "Объем",
    "wizard.directionForm.address": "Адрес",
    "report.name": "Заказ на расчет №",
    "constants.typeOfCargoFull.vehicle": "Машины",
    "crudForm.field.country": "Страна",
    "crudForm.dictionary.field.parent": "Главная организация",
    "orderForm.warning.consigneeInfoMessage": "Информация о грузополучателе",
    "crud.field.totalCost.title": "Итоговая цена",
    "wizard.insuranceForm.price": "Стоимость",
    "trunking.form.deleteAll": "Удалить все",
    "form.field.password": "Пароль",
    "subscription.page.config.resetFree.value.MONTH": "Месяц",
    "orderForm.to": "Пункт назначения:",
    "crudForm.field.PORT_ADR": "Порт ⇄ Адрес",
    "crud.field.nds.title": "НДС",
    "crud.field.errors.title": "Ошибки",
    "form.field.fullName": "Ф.И.О.",
    "contacts.confirm.message": "Удалить эту извещаемую сторону? Действие невозможно отменить",
    "reports.lineChart.countOfFreight": "Морской фрахт",
    "crud.field.aviaCompany.title": "Авиакомпания",
    "constants.charging.PORT_ADR": "Порт ⇄ Адрес",
    "documents.file.download.success": "Документ успешно скачан",
    "crudForm.field.RequiredServices": "Обязательность услуги",
    "modal.orderInfo": "Информация по заказу",
    "detailsOfCalculation.calculationsIsEmpty.bot": "Или направьте запрос в один клик, и в случае наличия тарифа мы сообщим его Вам.",
    "reports.field.btn.restart": "Перегенерировать",
    "error.ogrn.size.13": "Введите корректный ОГРН (13 цифр)",
    "customsOfCargo.form.removeArticles": "Удалить товар",
    "deliveryOrders.cargoTracking.table.quantity": "Кол-во",
    "detailsOfCalculation.railway_station_services": "Услуги на станции",
    "constants.customHouseAir.inPort": "Таможенное оформление в азропорту разгрузки",
    "crud.field.hub.title": "Хаб",
    "reports.field.btn.search": "Показать",
    "detailsOfCalculation.fields.cargo": "Груз",
    "crud.field.reportDetail.title": "Подробный отчет",
    "common.documents.nameOfDocument": "Название документа",
    "form.field.vehicle": "Машины",
    "reports.lineChart.countOfAllOrganization": "Морской фрахт + Автоперевозка",
    "templates.documents.table.headers.default": "Шаблон по умолчанию",
    "BOL_WITHOUT_SYMBOL": "Коносамент",
    "reports.types.POPULAR_DESTINATIONS_COUNTRY": "Популярные направления (по странам)",
    "detailsOfCalculation.airport_service": "Услуги в аэропорту",
    "role.user.adminOrganization": "Администратор организации",
    "detailsOfCalculation.filters.cancel": "Сбросить",
    "partner.generalInformation.form.field.email": "Официальный Email",
    "detailsOfCalculation.fields.container": "Груз",
    "crudForm.dictionary.field.name": "Название",
    "reports.lineChart.months": "Месяцы",
    "crud.field.modifier.title": "Тип",
    "AWB_WITHOUT_SYMBOL": "Авианакладная",
    "form.field.organization.name": "Название",
    "form.wizard.seo.title": "Калькулятор расчета стоимости доставки грузов онлайн",
    "articles.more.than.cargo": "Вес таможенного груза превышает вес перевозимого",
    "operatorAdmin.directories.forwarders": "Исполнители",
    "site.leftMenu.link.forwarders": "Экспедиторам",
    "importTranslates.title": "Импорт переводов хабов/гео-объектов",
    "modal.contactUs.failBody": "Не удалось отправить сообщение. Пожалуйста, повторите попытку позже.",
    "reports.alert.waitRestart": "Действие успешно завершено. Необходимо подождать 1 минуту пока сгенерируются новые графики",
    "detailsOfCalculation.callManager.modalSuccessBody": "Ваш запрос будет рассмотрен в ближайшее время. Коммерческое предложение будет выслано на указанный вами электронный адрес.",
    "crud.field.active.yes": "Действует",
    "crud.disclaimers.railway.conditionText": "дисклеймер",
    "crud.field.vehicleRates.distanceRange.title": "Диапазон расстояний",
    "detailsOfCalculation.fields.containers": "Контейнеры",
    "crud.field.active.title": "Активен",
    "requestStatus.switch": "Переход на другой тариф",
    "crud.field.profile.title": "Профиль",
    "detailsOfCalculation.createDate": "Дата расчёта",
    "form.order.customsCode": "Код ТНВЭД",
    "indicator.deliveryToRecipientDate.plan": "Дата доставки получателю (план)",
    "operatorAdmin.directories.transportHubs": "Транспортные хабы",
    "crudForm.bulkOperations.field.selectedItems": "Выбранных записей",
    "common.contactsTitle": "Контакты",
    "crud.field.forwarderTemplate.title": "Тип бланка",
    "constants.insuranceTransportType.land": "Сухопутная",
    "link.back.text": "< Вернуться назад",
    "reportResource.report.avg.timeExecution": "Среднее время выполнения запросов (секунд)",
    "report.transportation.road": "автоперевозка",
    "crud.field.nds.include": "вкл",
    "wizard.cargo.totalVolume": "Общий объем",
    "crud.field.countriesRoute.title": "Направление",
    "crudForm.dictionary.field.nameTemplate": "Название шаблона",
    "report.transportation.multimodal.rail": "мультимодальная перевозка (жд + авто)",
    "form.field.commission.addStr.common": "Интервал",
    "form.field.commission.consolidator.menu": "Консолидатор",
    "crud.field.createDateTime.title": "Дата создания",
    "confirm.order.reset": "Ваш заказ успешно очищен",
    "crudForm.dictionary.field.code.placeholder": "Введите символьный код",
    "userRegistration.passwordError": "Пароль должен содержать не менее 8 символов, включая заглавные и строчные буквы и не менее одной цифры",
    "crud.field.periodStart.title": "Период (начало)",
    "crud.field.conditionText.title": "Дисклеймер",
    "indicator.invoiceDate": "Счет не выставлен",
    "crudForm.field.notRequired": "Не обязательно",
    "deliveryOrders.orderList.status.onTheWay": "в пути",
    "crudForm.field.transitCargo": "Транзит",
    "crudForm.field.forWM": "за w/m",
    "form.field.confirmPassword": "Пароль (повторите)",
    "detailsOfCalculation.fields.general": "Сборный груз",
    "crudForm.dictionary.field.organizationName": "Название организации",
    "ordersList.plan": "* план",
    "AIRPORT_HUB": "Аэропорт",
    "crud.field.legalAddress.title": "Юрид. адрес",
    "orderCalculation.hidePriceLabelHover": "Войдите или зарегистрируйтесь, чтобы увидеть стоимость",
    "form.field.firstName.placeholder": "Введите имя",
    "crudForm.field.weightUnit": "Единица измерения веса",
    "reportResource.downloadReportExcelBtn": "Скачать отчет (Excel)",
    "common.settingsBtn": "Настройка",
    "crud.field.objectType.title": "Тип объекта",
    "partner.rates.cargo": "Груз",
    "orderForm.po": "Референс груза:",
    "site.footer.developedBy": "Разработано в",
    "constants.typeOfRoadGeneral.ldm": "с учетом LDM",
    "person.generalInformation": "Общая информация",
    "partner.generalInformation.form.field.factAddress": "Почтовый адрес",
    "crud.field.chargingName.title": "Тарификация",
    "crudForm.dictionary.field.rates_currency": "Курс к другим валютам:",
    "crudForm.dictionary.field.insuranceRate": "Таблица определения ставки страхования",
    "wizard.insuranceForm.addRange": "Добавить",
    "constants.month.december": "Декабрь",
    "form.field.postcode": "Почтовый код",
    "report.transportation.vehicle": "магистральная перевозка",
    "crud.field.companyName.title": "Компания",
    "deliveryOrders.status.AT_DESTINATION": "В пункте назначения",
    "common.someRequired": "Необходимо заполнить, как минимум, для одного типа контейнера",
    "modal.changePassword.oldPassword.placeholder": "Введите старый пароль",
    "partner.rates.public.crud": "Тарифы",
    "crudForm.bulkOperations.nameCode.copyLink": "Скопировать ссылку",
    "constants.charging.distance": "За км",
    "deliveryOrders.orderBlock.orderActions.details": "Подробнее",
    "deliveryOrders.cargoInformation.cargoAmountTitle": "Кол-во",
    "crud.field.error.geoObject.stationExists": "Невозможно удалить географический объект, к которому привязаны жд станции",
    "detailsOfCalculation.orderTable.vehicle.type": "Тип",
    "detailsOfCalculation.fields.billing": "Тарификация",
    "subscription.main.usage.text": "Расчеты (использовано/всего): ",
    "wizard.directionForm.airport": "Аэропорт",
    "wizard.cargo.form.size.M": "м",
    "operatorAdmin.insuranceRates": "Ставки страхования",
    "orderForm.of": "от",
    "crud.field.fromLocation.title": "Откуда",
    "templates.documents.edit.form.nofile": "Файл не выбран",
    "reports.types.POPULAR_DESTINATIONS": "Популярные направления",
    "partner.bankDetails.form.field.fullName.placeholder": "Введите наименование юридического лица",
    "person.button.changePassword": "Сменить пароль",
    "crudForm.field.maxSize": "Максимальный размер места",
    "crud.disclaimers.railway.railwayStationName": "ж/д станция",
    "crud.field.seaPort.title": "Порт",
    "operatorAdmin.title": "Панель администратора",
    "crud.field.departureDataNotFill": "Данные грузоотправителя не заполнены",
    "crud.filter.deleteFilterConfirm": "Вы уверены, что хотите удалить фильтр?",
    "subscription.main.text": "Основная информация:",
    "page.expiredTariffs.road-transportation": "Автоперевозка",
    "error.corrAccount.size.20": "Введите корректный номер корр. счёта (20 цифр)",
    "crud.field.profileId.title": "Профиль",
    "crudForm.field.intraportForwarding.auto.placeholder": "Вводится автоматически",
    "templates.documents.table.headers.name": "Название",
    "common.documents.addDocumentOrder": "Добавление записи",
    "constants.franchiseTypes.fixed": "Фиксированная",
    "wizard.subscribe.message.second": "Мы присылаем не более 2 писем в месяц. Никакого спама – только актуальная информация.",
    "constants.charging.rate": "Ставка",
    "report.cargo.hourly": "Сборный груз",
    "crud.field.error.surcharge.cantremove": "Невозможно удалить надбавку, для которой указаны значения",
    "crud.field.confirmationCode.title": "Код подтверждения",
    "organizationsProfiles.status.NEW": "Новый",
    "deliveryOrders.status.ARRIVED_TO_DEPARTURE_HUB": "Прибыл в HUB отправления",
    "crudForm.field.freightConditions": "Условия фрахта",
    "crud.field.seaLineId.title": "Линия",
    "constants.month.april": "Апрель",
    "orderForm.modal.driverPhone": "Телефон водителя",
    "crudForm.field.airTransportation.price": "Стоимость",
    "wizard.tooltip.useOtherContainer": "Для ваших параметров груза рассмотрите заказ перевозки контейнерами.",
    "detailsOfCalculation.toDoor": "довоз до двери",
    "detailsOfCalculation.priceOverTime": "Указана цена за минимальное время ({minHour} {hour}), далее действует почасовая оплата ({priceForHour} в час)",
    "crudForm.dictionary.field.organizationId.placeholder": "ID организации",
    "crudForm.field.airTransportation.auto.placeholder": "Вводится автоматически",
    "crud.form.add.title": "Добавление записи",
    "wizard.cargo.form.vehicleType": "Тип транспортного средства",
    "documents.replace.doc": "Такой документ уже загружен. Заменить новым?",
    "widget.order": "Заказ",
    "deliveryOrders.ownerInfo": "Заказчик",
    "partner.rates.complexTariff": "Комплексные тарифы",
    "detailsOfCalculation.details": "Что входит в стоимость",
    "logistBlock.plan": "План",
    "modal.changePassword.passwordConfirmation": "Повторите пароль",
    "detailsOfCalculation.insurance.title": "Страхование груза",
    "crudForm.field.betweenTheCity": "Между городами",
    "common.equalsroadTransit": "Транзитный хаб не должен совпадать с хабом отправления или хабом назначения",
    "crudForm.dictionary.tab.tabHatch": "Отсек",
    "form.button.next": "Далее",
    "crud.field.error.aviaCompany.airTariffExists": "Невозможно удалить авиакомпанию, для которой существуют авиатарифы",
    "form.wizard.allTerminals": "Все терминалы",
    "crud.disclaimers.airline.": "",
    "partner.userInfo.form.firstName.placeholder": "Введите имя",
    "modal.contactUs.title": "Связаться с нами",
    "detailsOfCalculation.fields.railwayOperator": "Жд оператор",
    "person.title": "Информация о клиенте",
    "constants.commissionTypes.percent": "Процент с продаж",
    "crudForm.dictionary.languages.title": "Языки",
    "wizard.cargo.form.typeOfCargo": "Тип груза",
    "reports.barChart.SEA_PORT": "Порты",
    "form.field.price": "Сумма",
    "partner.geography.geoTypes.country": "Страна",
    "page.notFound.text": "0",
    "deliveryOrders.order": "Заказ",
    "subscription.page.config.resetFree.value.WEEK": "Неделя",
    "logistBlock.documentsDate": "Получение документов",
    "crud.field.sellerRewardPercent.title": "Процент вознаграждения продавца",
    "wizard.directionForm.addressTo": "Город",
    "detailsOfCalculation.fields.conversionPercent": "Конвертация",
    "operatorAdmin.directories.railwayDisclaimers": "Условия по ж/д перевозкам",
    "form.wizard.title.common": "Другое",
    "indicator.pointOfArrival.departure.fact": "Дата отправления из хаба (факт)",
    "customsOfCargo.form.title": "Справочный расчет таможенных платежей",
    "deliveryOrders.orderList.excelReport": "Отчет в Excel",
    "excel.tariff.no.identify.direction": "Не удалось найти главный город",
    "modal.orderInfo.fromLocation": "Куда",
    "partner.generalInformation.dictionaries.title": "Профиль компании",
    "orderForm.cargoDescription": "Наименование груза:",
    "site.footer.menu.agreement": "Условия использования",
    "orderCalculation.hidePriceLabelModal": "Чтобы увидеть стоимость, вам нужно войти в систему или зарегистрироваться",
    "templates.documents.btn.downloadTestWord": "Посмотреть в WORD",
    "auth.fail.message": "Неверный логин или пароль.",
    "crud.field.ON_CARGO": "На груз",
    "modal.orderInfo.resultLink": "Смотреть отчет",
    "OF_PICKUP": "погрузки",
    "form.field.cityAndAddress": "Город, адрес",
    "button.logout": "Выйти",
    "orderForm.editSide": "Редактировать",
    "crudForm.field.airTransportation.placeholder": "Введите данные",
    "page.expiredTariffs.air-transportation": "Авиаперевозка",
    "indicator.customsClearance.departure.fact": "Таможенное оформление, отправление (факт)",
    "partner.generalInformation.form.field.email.placeholder": "Введите официальный Email",
    "crud.field.resetedUsageLimit.title": "Сброшено успешно",
    "modal.contactUs.email": "Email",
    "crud.field.error.geoObject.portExists": "Невозможно удалить географический объект, для которого существуют порты",
    "operatorAdmin.directories.airlines": "Авиакомпании",
    "crud.field.type": "Тип услуги",
    "partner.generalInformation.form.field.name.placeholder": "Введите наименование",
    "form.field.phone": "Телефон",
    "mainMenu.subscriptions.conf": "Конфигурация",
    "detailsOfCalculation.register": "зарегистрироваться",
    "deliveryOrders.listItem.status": "Статус",
    "subscriptionTariffs.title": "Подписки",
    "common.changeSelfOrganization": "Нельзя изменить организацию у самого себя",
    "crudForm.bulkOperations.form.field.email": "Адрес эл. почты",
    "error.numberAccount": "Введите корректный номер счёта (20 цифр)",
    "detailsOfCalculation.fakePartPaths": "Стоимость на данном участке необходимо уточнить дополнительно",
    "BOL": "Коносамент:",
    "wizard.cargo.form.insurance.interest": "Вид товара",
    "crud.field.externalAPI.title": "Внешние API",
    "indicator.pointOfDestination.arrival": "Прибытие в пункт назначения",
    "partner.userInfo.form.middleName.placeholder": "Введите отчество",
    "site.footer.subscribe.title": "Введите адрес своей почты и получайте свежие новости от нашего сервиса",
    "indicator.pointOfArrival.arrival": "Дата прибытия в хаб назначения",
    "reports.barChart.COUNTRY": "Страны",
    "detailsOfCalculation.ContainerType.Label": "Тип контейнера",
    "orderForm.consignee": "Грузополучатель:",
    "page.notFound.tittle": "Данная страница не найдена",
    "form.wizard.delivery": "Пункт назначения",
    "crud.field.newVersion.title": "Новое значение",
    "deliveryOrders.orderInfo.title": "Информация о заказе",
    "deliveryOrders.status.STANDBY": "Приостановлен",
    "common.documents.order.title": "Список всех документов заказа",
    "modal.contactUs.name": "Имя",
    "register.welcome": "Регистрация",
    "crud.field.nds.notInclude": "не вкл",
    "partner.rates.plato": "Платон",
    "operatorAdmin.directories.languages": "Языки",
    "form.field.email.placeholder": "Введите email",
    "constants.charging.betweenCities": "Между городами",
    "detailsOfCalculation.filters.error.text": "Произошла ошибка, пожалуйста, измените параметры поиска",
    "wizard.direction.title": "Расчет перевозки",
    "customsOfCargo.fields.quantity": "Количество",
    "crudForm.field.franchiseType": "Тип франшизы",
    "crudForm.field.airBillRate": "Цена за авианакладную",
    "circular.dependence": "Нельзя привязать элемент к самому себе",
    "user.sentRestorePassword": "На Ваш email отправлено письмо с инструкциями для восстановления пароля",
    "orderForm.numberOfFirstContainer": "Номер первого контейнера:",
    "detailsOfCalculation.tryTo": "Попробуйте ",
    "form.field.exporter": "Экспортер",
    "crud.filter.showExpired": "Показать",
    "crudForm.field.file": "Файл",
    "partner.generalInformation.form.field.phone.placeholder": "Введите телефон",
    "templates.documents.edit.form.header.edit": "Редактирование шаблона",
    "user.passwordDontMatch": "Введенные пароли не совпадают",
    "indicator.pointOfPickup.load.plan": "Дата погрузки (план)",
    "crud.field.dateFrom.title": "Срок действия от",
    "constants.routeType.localDeparture": "Внутренние (отправление)",
    "logistBlock.cargoTracking": "Отслеживание груза",
    "partner.generalInformation.form.field.phone": "Телефон",
    "detailsOfCalculation.orderTable.amount": "Количество",
    "crud.edit.bulkOperation.element": "эл.",
    "crud.label.yes": "Да",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.fifo.container.40HC": "Неверная стоимость для FIFO контейнера 40HC. Строка {0}, столбец {1}.",
    "operatorAdmin.organizations": "Организации",
    "crudForm.field.percent": "Процент",
    "form.field.commission.container.from": "Контейнеров от",
    "form.order.internalId": "Референс груза",
    "confirm.regenerate": "Отправить новый код",
    "templates.documents.edit.form.cancel": "Отмена",
    "form.field.cost": "Стоимость",
    "crud.field.forwarderId.title": "Исполнитель",
    "partner.geography.arrivalRegions": "Регионы назначения",
    "deliveryOrders.orderInfo.email": "E-mail",
    "crud.field.createOrderDate.title": "Дата расчета",
    "detailsOfCalculation.hourRP": "часа",
    "deliveryOrders.cancel": "Отменить",
    "crudForm.dictionary.field.coordinates.longitude.placeholder": "Долгота",
    "orderList.NumberColumnTitle": "№ заказа",
    "crudForm.field.period.on.6m": "На полгода",
    "crud.filter.exclude": "Не включен в тариф",
    "crudForm.dictionary.field.organizationProfiles": "Профили организаций",
    "importTranslates.notSelect": "Не указано",
    "deliveryOrders.orderParameters": "Параметры заказа",
    "crud.field.country.title": "Страна",
    "partner.commission": "Вознаграждение",
    "crud.field.cargoType.title": "Тип груза",
    "calculationUpdate.fail": "Невозможно обновить расчет, используя примененные ранее тарифы. Предлагаем сделать новый расчет по данному маршруту.",
    "logistBlock.cargoReadyDate": "Готовность груза",
    "reports.types.POPULAR_DESTINATIONS_CARGO": "Популярные направления (по типам грузов)",
    "reports.barChart.SEA_FREIGHT_TARIFF": "Морские тарифы",
    "RAILWAY_HUB": "Ж/Д станция",
    "crud.field.generalCargo.title": "Сборный груз",
    "logistBlock.invoiceDate": "Дата выставления счета",
    "crud.field.historyBillingList.crud.title": "Текущий тариф",
    "crudForm.field.forKg": "за кг",
    "LogisticDocType.INVOICE": "Инвойс",
    "deliveryOrders.orderBlock.orderActions.delete": "Удалить",
    "page.expiredTariffs.airport-services": "Услуги в аэропорту",
    "orderForm.deleteSide": "Удалить",
    "form.field.typeFrom": "Тип пункта отправления",
    "constants.PAID": "Оплачен",
    "notifications.type.ExpiredCalculation.message": "Ваш сохраненный расчет № order.Id устарел.",
    "detailsOfCalculation.cargoIsOverSize.top": "Груз с такими параметрами не может быть перевезен в стандартном ТС, используемом на данном маршруте.",
    "modal.changePassword.password": "Новый пароль",
    "confirm.modal.title": "Код подтверждения",
    "orderList.PriceColumnTitle": "Цена",
    "constants.charging.hourly": "Почасовая",
    "partner.services.serviceTypes.services": "Услуги",
    "crudForm.button.save": "Сохранить",
    "wizard.field.containersNumber.placeholder": "Введите количество контейнеров",
    "crud.field.container.title": "Контейнер",
    "indicator.pointOfOrigin.departure.plan": "Дата отправления из хаба (план)",
    "form.field.cost.placeholder": "Введите стоимость",
    "detailsOfCalculation.calculationInfo": "Данная ставка включает:",
    "crudForm.field.AIR_ADR": "Аэропорт ⇄ Адрес",
    "importTranslates.name.zh": "Название (ZH)",
    "crud.field.executionTime.second.title": "Время выполнения",
    "crud.field.phone.title": "Телефон",
    "subscription.request.status.NEW": "Новая",
    "partner.generalInformation.form.field.legalAddress": "Юридический адрес",
    "general.cargo.compound.containerBtn": "Контейнер",
    "importTranslates.btn.wait": "Подождите...",
    "crudForm.field.relativeRate": "Ставка, (%)",
    "crudForm.field.transitHub": "Транзит через",
    "ratingReport.modal.title": "Детализирующий отчет по пользователю: ",
    "crud.field.error.geoObject.wrongLatitude": "Широта должна быть в диапазоне от -90 до 90",
    "wizard.cargo.form.weight.placeholder.LB": "Фунты",
    "crud.field.latitude.title": "Широта",
    "subscription.history.date": "Дата оформления ",
    "partner.rates.general": "Сборный груз",
    "partner.generalInformation.form.title": "Информация о компании",
    "ratingReport.generateReport": "Сформировать отчет",
    "wizard.customsClearance.noTariff": "Расчет стоимости таможенного оформления по данному направлению невозможен",
    "detailsOfCalculation.orderTable.to": "Куда",
    "crud.field.numberAccount.title": "Номер счёта",
    "crud.field.resetUsageLimitBtn.title": "Сбросить",
    "crudForm.field.period.on.3m": "На 3 месяца",
    "errorLimit": "Уважаемый пользователь, согласно вашей подписке, у вас закончились доступные расчеты.",
    "crud.uploadFromExcel.example": "Образец формы",
    "crud.field.charging.title": "Тарификация",
    "orderForm.orderName": "Заказ",
    "crud.field.commentDescription.title": "Комментарий",
    "confirm.already.done": "Вы уже подтвердили свою регистрацию ...",
    "order.location.address.required": "Поле обязательно к заполнению",
    "detailsOfCalculation.callTheManager": "Уточните дополнительно",
    "wizard.cargo.form.weight.placeholder.KG": "КГ",
    "form.wizard.title.to": "Куда",
    "detailsOfCalculation.integrator": "Экспедитор",
    "crudForm.bulkOperations.field.forItems": "Применить",
    "crudForm.field.port": "Порт",
    "detailsOfCalculation.hourMn": "часов",
    "crud.field.toHub.name.title": "Хаб назначения",
    "crud.disclaimers.vehicle.conditionText": "дисклеймер",
    "page.expiredTariffs.sea": "Морской фрахт",
    "crudForm.field.period.on.2m": "На 2 месяца",
    "common.documents.file": "Прикрепленный файл",
    "crud.field.transitHub": "Transit hub",
    "OF_ORIGIN": "отправления",
    "crud.field.lastCalculationDateTime.title": "Дата и время последнего расчета",
    "partner.rates.container": "Контейнеры",
    "operatorAdmin.directories.chatbot": "Чат-боты",
    "subscription.chooseTariff.btn": "Выбрать подписку",
    "crudForm.field.PORT_RAILWAY": "Порт ⇄ Ж/д Станция",
    "crud.disclaimers.vehicle.countryName": "страна",
    "partner.bankDetails.form.field.bik": "БИК",
    "orderForm.modal.cargoWeight": "Вес груза:",
    "form.field.private.placeholder": "Подтверждаю согласие",
    "common.roleIsUnknown": "-",
    "partner.rates.vehicle": "Машины",
    "deliveryOrders.status.CUSTOMS_CLEARANCE": "Таможенное оформление",
    "crudForm.field.airTransportation.transitTime": "Расчетное время",
    "ADDRESS": "Город",
    "crudForm.field.period.on.1m": "На 1 месяц",
    "constants.month.january": "Январь",
    "common.documents.creationDate": "Дата создания",
    "templates.documents.edit.form.alert.exist": "Для данных параметров шаблон уже создан.",
    "crud.field.enabled.title": "Состояние",
    "crudForm.field.ldmValue": "Значение LDM",
    "crudForm.value.placeholder": "Значение",
    "crud.field.type.title": "Тип",
    "form.field.bothSides": "В обе стороны",
    "crud.field.vehicleRates.title": "Машины",
    "customsOfCargo.remark": "Данный расчёт носит ознакомительный характер",
    "crudForm.field.status.text": "Статус заявки",
    "form.field.commission.road_transportation.menu": "Автоперевозчик",
    "detailsOfCalculation.insurance.franchise": "Франшиза",
    "tracking.fields.error.fact": "Дата факта заполнена",
    "detailsOfCalculation.orderTable.vehicle.bodyType": "Тип кузова",
    "constants.routeType.export": "Экспорт",
    "common.copy.error": "Failed to copy data",
    "LogisticDocType.ANOTHER": "Другой",
    "operatorAdmin.directories.customsTerminals": "Таможенные терминалы",
    "rates.error.accessDenied": "У организации нет необходимых прав на создание тарифа",
    "crud.field.fromHub.name.title": "Хаб отправления",
    "common.invalidZipCodes": "Неверная строка зип кодов",
    "indicator.pointOfPickup.departure.fact": "Дата отправления (факт)",
    "partner.generalInformation.form.field.profileStatus.NEW": "Новый",
    "deliveryOrders.status.IN_TRANSIT": "В транзите",
    "page.expiredTariffs.railway-transportation": "Ж/д перевозка",
    "clientErrorMsg.disclaimer.exists": "Данный десклеймер уже существует.",
    "crud.field.confirmed.title": "Подтвержден",
    "form.field.locationType": "Тип местоположения",
    "crud.field.rates.title": "Процент от стоимости",
    "common.documents.error.id.none": "Указанный номер заказа не найден",
    "partner.geography.geoTypes.regionOfWorld": "Регион мира",
    "crud.edit.downloadCSV": "Скачать все тарифы",
    "constants.month.june": "Июнь",
    "report.unit.m": "м",
    "crud.field.station.title": "Станция",
    "operatorAdmin.directories.airs": "Самолеты",
    "crud.field.transactionId.title": "Номер договора",
    "crud.field.error.airport.airportTariffExists": "Невозможно удалить аэропорт, для которой существуют тарифы в аэропорту",
    "partner.rates.portServices": "Портовые услуги",
    "constants.ordersSortFilterCurrency.cny": "CNY",
    "detailsOfCalculation.filters.save": "Применить",
    "crudForm.field.commissionIncluded": "Включена",
    "crud.field.sellerRewardMin.title": "Мин. вознаграждение продавца",
    "constants.surchargeTypes.onCargo": "На груз",
    "crudForm.field.minTimeForTransport": "Мин. время, (час)",
    "orderForm.warning.notifyPartyMessage": "Добавить извещаемую сторону",
    "operatorAdmin.directories.geography": "География",
    "detailsOfCalculation.departureDate": "Дата отправления",
    "deliveryOrders.orderList.headerTable.internalId": "Референс груза",
    "constants.month.july": "Июль",
    "partner.geography.geoTypes.specialRegion": "Специальный регион",
    "crudForm.field.IDschedule.placeholder": "Введите ID расписания",
    "form.field.commission.general_cargo.to": "W/m до",
    "indicator.pointOfDestination.arrival.plan": "Прибытие в пункт назначения (план)",
    "crud.field.error.seaPort.portCantBeTerminal": "Порт с терминалами не может быть терминалом другого порта",
    "templates.documents.edit.form.default": "Шаблон по умолчанию",
    "form.address.defaultPlaceholder": "Введите город/населенный пункт",
    "crud.field.error.geoObject.parentGeoObjectExists": "Невозможно удалить географический объект, который является родительским",
    "crud.field.password.title": "Пароль",
    "deliveryOrders.totalCost": "Стоимость",
    "modal.contactUs.phone": "Телефон",
    "partner.rates.air": "Авиаперевозка",
    "deliveryOrders.orderList.status.bookingReceived": "заказ получен",
    "crudForm.field.charging": "Тарификация",
    "crudForm.field.minCostForTransport": "Мин. цена перевозки",
    "form.field.typeTo": "Тип пункта назначения",
    "detailsOfCalculation.transportationParameters": "Параметры перевозки",
    "crud.field.personName.title": "Ф.И.О.",
    "crudForm.field.common.documents.typeOfDocument": "Тип документа",
    "documents.email.success": "Документ успешно отправлен на почту",
    "crud.field.error.railway.railwayStationTariffExists": "Невозможно удалить железнодорожную станцию, для которой существуют тарифы на услуги на станции",
    "crudForm.field.costForHour": "Цена за час",
    "constants.customHouse.inPort": "Таможенное оформление в порту разгрузки",
    "orderForm.numberOfContainers": "Контейнеров:",
    "crudForm.dictionary.btn.addHatch": "Добавить отсек",
    "wizard.field.containersOverweight": "Перевес",
    "crud.field.description.title": "Описание",
    "crud.filter.selectFilter": "Выберите фильтр...",
    "partner.generalInformation.form.updateSuccess": "Данные успешно сохранены",
    "modal.changePassword.passwordConfirmation.placeholder": "Введите новый пароль",
    "crudForm.field.hub": "Хаб",
    "wizard.parameters.title": "Параметры",
    "operatorAdmin.customsCalculator": "Таможенный калькулятор",
    "partner.users": "Пользователи",
    "form.field.lastName.placeholder": "Введите фамилию",
    "crudForm.field.customsClearance.included": "Включен в тариф",
    "page.expiredTariffs.port-services": "Портовые услуги",
    "importTranslates.alertNotSelectCondition": "Выберете столбец со значениями условий",
    "page.expiredTariffs.freight-surcharges": "Надбавки фрахта",
    "modal.orderInfo.error": "Ошибка",
    "crudForm.field.interestType": "Вид груза",
    "trunking.plato.saveBtn": "Сохранить",
    "site.header.phone": "Тел.",
    "crud.field.relativeRate.title": "Cтавка, (%)",
    "detailsOfCalculation.from": "от",
    "indicator.pointOfOrigin.arrival.plan": "Дата прибытия в хаб (план)",
    "importTranslates.name.ru": "Название (RU)",
    "partner.geography.form.country": "Страна",
    "customsOfCargo.form.addArticles": "Добавить товар",
    "reportResource.report.countFail": "Количество неудачных запросов",
    "crud.form.edit.title": "Редактирование записи",
    "deliveryOrders.orderBlock.orderActions.editLogistic": "Отслеживание",
    "crud.field.allCountries.title": "Все страны",
    "pallet.too.many.directions": "В файле более одного направления",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.port.from.empty": "Неверный код порта отправления. Строка {0}, столбец {1}.",
    "common.roleAdmin": "Администратор организации",
    "crudForm.dictionary.field.maxFloorPressure": "Допустимое давление на пол, (кгс/м2)",
    "TRANSPORT_HUB": "Пункт",
    "detailsOfCalculation.askInputdetailOfOrder": "Если вы можете внести данные сейчас, пожалуйста, внесите, если нет, - ничего страшного, мы сделаем это за вас как только вы направите их нам по электронной почте. Система запомнит их для будущих перевозок.",
    "constants.typeOfCargo.fcl": "FCL",
    "crudForm.field.weightFrom": "От (кг)",
    "crud.field.price.title": "Цена",
    "toHomepage": "На главную",
    "form.wizard.title.customsOfCargo": "Место таможенного оформления",
    "confirmationCode.notFound": "Код подтверждения не найден",
    "subscription.tariff.create.modal.title": "Выберите подписку",
    "modal.orderInfo.toLocation": "Откуда",
    "deliveryOrders.order.commonInfoTitle": "Общая информация",
    "form.order.notifyParty": "Извещаемая сторона",
    "crud.field.generalCargoRate.title": "Сборный груз",
    "crud.field.limitCalculations.title": "Количество расчетов",
    "common.info": "Информация",
    "constants.modifier.public": "Общедоступный",
    "detailsOfCalculation.alert.invalid": "Расчет устарел, пожалуйста, уточните дополнительно",
    "crudForm.field.forBL": "за B/L",
    "crud.field.arrivalDataNotFill": "Данные грузополучателя не заполнены",
    "wizard.insuranceForm.include": "Включить в расчет",
    "constants.month.september": "Сентябрь",
    "form.order.title": "Оформление заказа",
    "crud.field.counterUsePartnersApi.title": "Внешние партнеры (запросы / полученные тарифы)",
    "importTranslates.resultCountSuccess": "Успешно импортировано:",
    "crud.field.error.geoObject.seaportExists": "Невозможно удалить географический объект, к которому привязаны порты",
    "form.": "0",
    "site.footer.subscribe.error": "Произошла ошибка сервера. Пожалуйста, попробуйте позднее",
    "operatorAdmin.directories.seaConditions": "Условия по морским перевозкам",
    "passwordRecovery.title": "Восстановление пароля",
    "partner.bankDetails.form.field.ogrn.placeholder": "Введите ОГРН",
    "common.currency": "Валюта",
    "wizard.cargo.form.size.height.placeholder": "В",
    "passwordRecovery.hint": "Забыли пароль?",
    "operatorAdmin.personalOrders": "Заявки",
    "form.field.minKM": "Мин. расстояние",
    "site.leftMenu.link.personalArea": "Личный кабинет",
    "crud.field.conditions.title": "Условия",
    "form.field.terminal": "Терминал",
    "operatorAdmin.directories.typesOfGeneralCargo": "Коробки/Паллеты",
    "deliveryOrders.orderInfo.phone": "Телефон",
    "crudForm.field.ADR_ADR": "Адрес ⇄ Адрес",
    "page.accessDenied.prevLinkText": "Вернуться на предыдущую страницу",
    "customsOfCargo.fields.currency": "Валюта",
    "LogisticDocType.BILL": "Коносамент/авианакладная",
    "orderList.companyName": "Компания",
    "crudForm.field.thresholdWeight": "Общий вес груза до",
    "partner.rates.trunking.international": "Международная магистральная перевозка",
    "customsOfCargo.fields.incoterms": "Условия поставки по инкотермс",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.fifo.containers": "Нет ни одной стоимости перевозки. Строка {0}.",
    "detailsOfCalculation.orderTable.kg": "кг",
    "wizard.directionForm.railway": "Ж/Д станция",
    "trunking.form.counterFirstString": "Успешно загружены",
    "crud.remove.confirm.text": "Вы уверены, что хотите удалить выбранный элемент?",
    "crudForm.dictionary.field.translate.placeholder": "Введите перевод",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.sealine.not.found": "Отсутствует морская линия в системе. Строка {0}, столбец {1}.",
    "partner.bankDetails.form.field.corrAccount": "Номер корр. счёта",
    "subscription.tariff.create.request": "Оформить заявку",
    "form.order.email": "Email",
    "detailsOfCalculation.orderCreated.header": "Ваш заказ создан!",
    "crud.field.forwarder.title": "Исполнитель",
    "crudForm.field.generalCargoUnit": "Единица измерения",
    "crud.field.destinationPort.title": "Порт назначения",
    "common.documents.attachedDocumentsTitle": "Прикреплено документов",
    "crudForm.dictionary.field.id": "ID",
    "crud.field.historyBillingList.title": "История изменения подписок",
    "crud.field.portType.title": "Тип",
    "crudForm.field.region": "Регион",
    "crudForm.field.type": "Тип услуги",
    "subscription.switchToFree.btn.text": "Перейти на бесплатную подписку",
    "popupCreateOrder.header": "Внимание!",
    "wizard.subscribe.subscribeBtnText": "Подписаться",
    "deliveryOrders.confirmOfCancelOrder": "Вы уверены, что хотите отменить заказ?",
    "wizard.cargo.form.palletTypes": "Паллет",
    "common.documents.removeActionTitle": "Удалить",
    "report.cargo.container": "Контейнер",
    "constants.placeTypes.airportHub": "Airport",
    "detailsOfCalculation.successOrder.btnOk": "Перейти к заказам",
    "orderForm.arrivalDate": "Дата доставки:",
    "crud.uploadFromExcel.example.header": "Внимание!",
    "crudForm.field.work": "Действует",
    "partner.generalInformation.form.field.conversionPercent.placeholder": "Введите процент за конвертацию",
    "deliveryOrders.orderBlock.orderActions.edit": "Редактировать",
    "crud.field.organization.name.title": "Организация",
    "crudForm.field.cost": "Стоимость",
    "client.successUpdate": "Данные клиента обновлены",
    "crud.field.error.geoObject.airportExists": "Невозможно удалить географический объект, к которому привязаны аэропорты",
    "tracking.fields.error.update": "Ошибка сохранения",
    "crud.field.file.title": "Файл",
    "wizard.cargo.form.box.title": "Коробки/Паллеты",
    "crud.field.seaLineOrConsolidator.title": "Линия / Консолидатор",
    "site.copyright": "Все права защищены",
    "crudForm.field.from": "Действует от",
    "common.siteUsesCookie": "Agorafreight использует cookies для повышения удобства сайта. Оставаясь на сайте, Вы соглашаетесь с политикой их применения.",
    "wizard.cargo.form.size.width": "Ширина",
    "partner.freightSurcharges": "Надбавки фрахта",
    "subscription.history.idContract": "Идентификатор договора",
    "crud.filter.userFilter": "Пользовательский фильтр",
    "crud.field.railwayCompany.title": "Ж/д оператор",
    "form.field.TNVD": "ТНВД",
    "operatorAdmin.directories.lines": "Линии",
    "common.required": "Поле обязательно к заполнению",
    "crud.field.usageLimit.title": "Лимит запросов (использовано/всего)",
    "role.partner": "Partner",
    "crud.field.middleName.title": "Отчество",
    "deliveryOrders.mainInfo.comment": "Комментарий",
    "modal.currentUserInfo": "Профиль пользователя",
    "deliveryOrders.calculationInfo": "Информация о расчете",
    "crud.field.maxWeight.value.title": "Макс. вес",
    "crud.field.departureData.title": "Грузоотправитель",
    "container.oversized.cargo": "Негабаритный груз. Попробуйте уменьшить вес контейнера или свяжитесь с нами для уточнения параметров перевозки.",
    "form.error.commission.minComissionInvalid": "Минимальный размер вознаграждения не может быть больше максимального",
    "form.field.general": "Сборный груз",
    "modal.contactUs.question": "Ваш вопрос",
    "common.field.before": "до",
    "crud.field.code.title": "Код",
    "calculations-list.guest.result.registrationMsg": "Если хотите увидеть выгодные ставки на перевозку - зарегистрируйтесь.",
    "form.field.commission.integrator.menu": "Экспедитор",
    "form.field.organization.legalAddress": "Адрес регистрации",
    "crud.field.maxPalletCount.title": "Макс. паллетов",
    "templates.documents.type.orderkp": "Коммерческое предложение",
    "CMR_WITHOUT_SYMBOL": "CMR",
    "partner.geography.departureRegions": "Регионы отправления",
    "crudForm.field.nds.placeholder": "Введите НДС",
    "form.field.commission.general_cargo.price": "За w/m",
    "form.field.companyName": "Название компании",
    "form.field.cargoName": "Наименование груза",
    "partner.bankDetails.form.field.kpp": "КПП",
    "deliveryOrders.orderDate": "Дата заказа",
    "person.calculationInfo.invalid": "Расчеты устарели, нажмите, пожалуйста, кнопку чтобы обновить их",
    "constants.typeOfCargoFull.fcl": "Контейнеры",
    "crud.field.conversionPercent.title": "Процент конвертации",
    "constants.surchargeTypes.onFreight": "На фрахт",
    "service.dataBaseIsUnavailable": "База данных временно недоступна",
    "crudForm.field.airline": "Авиакомпания",
    "crudForm.field.weightLimit": "Максимальный вес груза",
    "templates.documents.btn.downloadTestPdf": "Посмотреть в PDF",
    "form.field.organization.name.placeholder": "Введите название",
    "dialog.confirm.yes": "Да",
    "report.vehicle.refrigerator": "Рефрижераторный фургон",
    "person.button.enter": "Личный кабинет",
    "trunking.form.uploadDesc": "Файл должен быть в формате .xlsx",
    "RAILWAY_NUMBER": "ЖДН:",
    "reports.barChart.SPECIAL_REGION": "Спец. регионы",
    "deliveryOrders.orderList.status.bookingConfirmed": "заказ подтвержден отправителем",
    "logistBlock.deliveryToRecipientDate": "Дата доставки получателю",
    "deliveryOrders.listItem.loadingDate": "Погрузка",
    "common.documents.uploadActionTitle": "Загрузить",
    "common.invalidSize": "Слишком длинное значение",
    "crud.field.roles.title": "Роли",
    "crudForm.field.zipCodes": "ZIP-коды",
    "deliveryOrders.orderInfo.contactPerson": "Контактное лицо",
    "importTranslates.column": "Столбец №",
    "crud.field.consolidatedCargo.title": "Сборный груз",
    "mainMenu.subscriptions.userRequests": "Заявки клиентов",
    "crud.field.coordinates.title": "Координаты (Ш х Д)",
    "wizard.directionForm.seaPort": "Морской порт",
    "partner.geography.geoTypes.region": "Регион",
    "common.add": "Добавить",
    "constants.insuranceTransportType.air": "Авиа",
    "indicator.pointOfPickup.ready.fact": "Дата готовности груза (факт)",
    "crudForm.field.franchise": "Франшиза",
    "form.order.user.phone": "Ваш телефон",
    "form.wizard.summary.title": "Форма с параметрами груза",
    "crud.field.nextBillingDate.title": "Дата следующего платежа",
    "order.createNewManual.detailsOrder.title": "Детали заказа",
    "detailsOfCalculation.fields.trunking": "Магистральная",
    "crud.disclaimers.airline.airportName": "аэропорт",
    "detailsOfCalculation.transitTimeZero": "менее суток",
    "crud.field.lastName.title": "Фамилия",
    "importTranslates.columnCondition": "Значения условий",
    "order.customs.already.exists": "Таможенный расчет для данного груза уже существует",
    "crud.field.currency.title": "Валюта",
    "templates.documents.table.headers.type": "Тип шаблона",
    "constants.charging.fromAirport": "Вывоз/ввоз в аэропорт",
    "operatorAdmin.directories": "Дисклеймеры",
    "operatorAdmin.directoriesSuperAdmin": "Справочники",
    "detailsOfCalculation.fields.priceIncludingVat": "Стоимость с НДС",
    "form.field.cargoName.placeholder": "Введите наименование груза",
    "crud.field.arrivalPlace.button.delete": "Удалить данные грузополучателя",
    "detailsOfCalculation.callManager.title": "Направить запрос",
    "orderForm.downloadFrom": "Загрузить из шаблона",
    "general.cargo.compound.to.container.40": "Сборный груз дешевле перевезти 40 футовым контейнером. Расcчитать перевозку контейнером или оставить без изменений?",
    "orderForm.cargoWeight": "Общий вес:",
    "crudForm.field.portsOfArrival": "Порты прибытия",
    "crudForm.field.commissionNotIncluded": "Не включена",
    "crud.tab.organization.contact": "Контактные данные",
    "detailsOfCalculation.successOrder.btnClose": "Закрыть",
    "tracking.fields.events": "События",
    "report.transportation.rail": "жд перевозка",
    "crudForm.dictionary.field.kind_containerType.placeholder": "Введите тим ёмкости",
    "deliveryOrders.listItem.totalPrice": "Цена",
    "detailsOfCalculation.orderTable.from": "Откуда",
    "wizard.button.search": "Поиск",
    "crud.field.toLocation.title": "Куда",
    "crudForm.field.intraportForwarding.addRange": "Добавить диапазон",
    "crud.field.contactPerson.title": "Контактное лицо",
    "passwordRecovery.setNewPassword": "Задать новый пароль",
    "importTranslates.typeCondtion": "Тип условия",
    "crudForm.field.airTransportation.addRange": "Добавить диапазон",
    "crud.field.documentAttachments.title": "Прикрепленные документы",
    "site.header.dropdown.edit": "Редактировать",
    "form.order.dateReady": "Дата готовности груза",
    "subscription.request.status.DECLINE": "Отклонена",
    "RAILWAY_WITHOUT_SYMBOL": "Ж/Д накладная",
    "detailsOfCalculation.orderTable.weight": "Вес",
    "partner.bankDetails.form.field.kpp.placeholder": "Введите КПП",
    "wizard.wrongDirection": "На данном направлении перевозка выбранного типа груза не осуществляется",
    "modal.orderInfo.container": "Контейнер",
    "partner.title": "Информация о компании",
    "crud.field.cost.title": "Стоимость",
    "form.wizard.seo.description": "Воспользуйтесь калькулятором расчета доставки грузов онлайн. Узнайте цену прямо сейчас",
    "person.detailsOfCalculation": "Детали расчета",
    "crud.field.periodEnd.title": "Период (конец)",
    "deliveryOrders.orderList.status.delivered": "доставлено",
    "form.field.commission.addStr.private": "Приватный интервал",
    "page.accessDenied.loginLinkText": "Перейти на страницу авторизации",
    "detailsOfCalculation.filters": "Фильтры",
    "acts.page.title": "Счета и Акты",
    "crud.field.countryId.title": "Страна",
    "confirm.success.text": "Регистрация успешно завершена!",
    "crudForm.field.commentDescription.placeholder": "Комментарий",
    "detailsOfCalculation.fields.total": "Итого",
    "templates.documents.page.title": "Шаблоны документов",
    "partner.rates.pallets": "Паллеты",
    "deliveryOrders.singleOrderTitle": "Заказ на доставку",
    "logistBlock.invoice": "Счет",
    "OF_ARRIVAL": "прибытия",
    "crudForm.field.minPrice": "Мин. цена",
    "general.cargo.compound.to.container.20": "Сборный груз дешевле перевезти 20 футовым контейнером. Расcчитать перевозку контейнером или оставить без изменений?",
    "role.client": "Client",
    "crud.field.costCargo.title": "Стоимость",
    "constants.routeType.localArrival": "Внутренние (прибытие)",
    "crud.filter.active": "Активен",
    "crudForm.field.transitSurcharge": "Надбавка за транзит",
    "crud.field.error.geoObject.transportHubsExists": "Невозможно удалить географический объект, к которому привязаны транспортные хабы",
    "crud.field.billingDate.title": "Дата оформления",
    "dictionary.translation.noSuchField": "Поля с таким названием нет в справочнике",
    "crud.field.seaPortId.title": "Порт",
    "crud.field.error.airport.airTariffExists": "Невозможно удалить аэропорт, для которой существуют авиатарифы",
    "deliveryOrders.orderId": "Номер заказа",
    "waiting.text": "Подождите",
    "crud.field.externalAPIAgora.title": "AGORA API",
    "crud.tab.mainParams": "Основные параметры",
    "AWB": "Авианакладная:",
    "customsOfCargo.fields.name": "Код ТНВЭД/Наименование",
    "organizationsProfiles.approveProfileConfirm": "Вы действительно хотите одобрить профиль?",
    "reportResource.report.countSuccess": "Количество успешных запросов",
    "form.order.truckCount": "Количество автомобилей",
    "crudForm.dictionary.field.translate": "Перевод",
    "importTranslates.resultCountError": "Ошибки импорта:",
    "common.close": "Закрыть",
    "crudForm.default.title": "Форма",
    "crudForm.field.IDschedule": "ID расписание",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.port.to.empty": "Неверный код порта прибытия. Строка {0}, столбец {1}.",
    "subscription.page.config.resetFree": "Интервал обновления счетчика бесплатных расчетов (free-тариф)",
    "partner.bankDetails.form.field.fullName": "Наименование юридического лица",
    "crud.field.billRate.title": "Цена за B/L",
    "partner.generalInformation.form.field.conversionPercent": "Процент за конвертацию",
    "reports.types.USER_COUNT": "Количество пользователей",
    "trunking.form.downloadTitle": "Просмотр последних загруженных тарифов",
    "crudForm.bulkOperations.field.filter": "Для всех отфильтрованных записей",
    "reports.types.POPULAR_DESTINATIONS_TREND_CARGO": "Популярные направления (по отношению к предыдущему периоду, по типам грузов)",
    "crudForm.field.airports": "Аэропорты",
    "subscription.tariff.period": "/ месяц",
    "crud.field.airline.title": "Авиакомпания",
    "crud.field.geoCoordinates.title": "Координаты (Ш х Д)",
    "widget.completed": "оформлен",
    "reports.types.ORDER_COUNT": "Количество запрошенных расчетов",
    "constants.vehicleDistanceRange.OVER_100": ">100 км",
    "crud.field.email.title": "Email",
    "conditionText": "дисклеймер",
    "user.notFound": "Пользователь не найден",
    "crud.field.requestGroup.id.title": "Номер заказа",
    "subscription.modal.add.btn.label": "Подписка",
    "common.documents.renameActionTitle": "переименовать",
    "constants.typeOfCargo.lcl": "LCL",
    "crudForm.field.intraportForwarding.placeholder": "Введите данные",
    "partner.userInfo.title": "Информация о партнере",
    "crud.field.regions.title": "Регионы",
    "orderForm.addSide": "Добавить извещаемую сторону",
    "crud.field.organizationId.title": "Организация",
    "crudForm.dictionary.field.maxLoad": "Предельная нагрузка, (кг)",
    "wizard.cargo.shipmentTotal": "Общий вес пересылки",
    "crud.disclaimers.airline.airlineName": "авиакомпания",
    "indicator.invoicePaymentDate": "Счет не оплачен",
    "detailsOfCalculation.detailInformation": "Подробная информация",
    "crud.disclaimers.vehicle.cityFromName": "откуда",
    "form.field.enter.placeholder": "Введите свои данные",
    "crudForm.field.accessLevel": "Уровень доступа",
    "detailsOfCalculation.fields.service": "Услуги",
    "wizard.cargo.totalWeight": "Общий вес",
    "importTranslates.name.en": "Название (EN)",
    "notifications.title": "Уведомления",
    "deliveryOrders.status.NEW": "Заказ получен",
    "deliveryOrders.orderBlock.orderActions.changeOwner": "Изменить владельца",
    "templates.documents.alert.beforeSave": "Перед сохранением убедитесь, что шаблон корректен при помощи кнопок ниже:",
    "importTranslates.btn.import": "Импорт",
    "crudForm.error.tariff.invalidDateFrom": "Дата начала действия тарифа не может быть больше даты окончания",
    "crudForm.field.pricePerCbm": "Цена за кубометр",
    "subscription.history.tariff": "Подписка ",
    "detailsOfCalculation.calculationsIsEmpty.top": "Приносим свои извинения. Мы постоянно совершенствуем нашу систему, но иногда не все расчеты могут считаться автоматически в момент времени.",
    "report.transportation.multimodal.avia": "мультимодальная перевозка (авиа + авто)",
    "reportResource.report.countCommon": "Количество запросов",
    "form.field.country": "Страна",
    "trunking.replaceFile": "Загрузите новый файл",
    "error.kpp.size.9": "Введите корректный КПП (9 цифр)",
    "form.field.commission.general_cargo.from": "W/m от",
    "form.field.firstName": "Имя",
    "detailsOfCalculation.save": "Сохранить",
    "constants.charging.ADR_RAILWAY": "Адрес ⇄ Ж/д Станция",
    "partner.forwarders": "Исполнители",
    "agreement.title": "Условия использования",
    "report.vehicle.term": "Изотермический фургон",
    "crud.filter.inactive": "Неактивные тарифы",
    "crudForm.field.calculationType": "Тип расчета",
    "form.wizard.header": "Получите расчет стоимости заказа",
    "modal.orderInfo.result": "Результат",
    "login.submit.facebook": "Войти через Facebook",
    "customsOfCargo.navTitle": "Товар",
    "form.field.commission.port_agent.menu": "Портовый агент",
    "partner.additions": "Надбавки",
    "LogisticDocType.CERTIFICATE": "Сертификат",
    "form.order.phone": "Телефон",
    "partner.rates.billOfLading": "Коносамент",
    "crudForm.field.commissionType": "Тип ввода комиссии",
    "crudForm.field.rates": "Ставка",
    "form.order.volume": "Объем груза",
    "crud.field.ABSOLUTE": "Абсолютная",
    "crudForm.field.price": "Цена",
    "palletTariff.form.addFile": "Прикрепить файл",
    "deliveryOrders.listItem.arrivalDate": "Доставка",
    "partner.userInfo.form.firstName": "Имя",
    "partner.bankDetails.form.field.bik.placeholder": "Введите БИК",
    "indicator.pointOfOrigin.arrival": "Дата прибытия в хаб отправления",
    "crud.field.country.from.title": "Страна откуда",
    "containerId.20.hint":"Введите 9 значный номер контейнера. Пример: 123456789",
    "containerId.40.hint":"Введите 11 значный номер контейнера. Пример: ABCD123456789",
    "reports.lineChart.countOfInWork": "В работе",
    "crudForm.field.forCbm": "за м3",
    "partner.generalInformation": "О компании",
    "constants.vehicleDistanceRange.UNDER_50": "<50 км",
    "constants.charging.AIR_ADR": "Аэропорт ⇄ Адрес",
    "crud.disclaimers.vehicle.cityToName": "куда",
    "templates.documents.edit.form.btn.file": "Прикрепить файл",
    "crud.field.infinite.title": "Бесконечные расчеты",
    "crud.remove.success": "Элемент успешно удален.",
    "tracking.fields.eventType": "Тип события",
    "crud.field.error.seaLine.portTariffExists": "Невозможно удалить морскую линию, для которой существуют тарифы в порту",
    "form.field.headerModalSettingsScan": "Настройки параметров поиска популярных направлений",
    "passwordRecovery.submit": "Восстановить пароль",
    "crudForm.field.minSum": "Минимальная сумма",
    "reports.field.dateFrom": "От",
    "wizard.tooltip.selectContainer40": "Выбрать контейнер.",
    "constants.customHouse.inDestinationPlace": "Таможенное оформление в конечном пункте назначения",
    "common.documents.title": "Список всех документов в системе",
    "crud.form.edit.template.title": "Редактирование шаблона",
    "crud.field.objectId.title": "ИД объекта",
    "constants.vehicleBodyType.term": "Изотермический фургон",
    "partner.geography.form.city": "Город",
    "detailsOfCalculation.calculationReportHeader": "Заказ на расчет №",
    "form.order.shippingDatePlan": "Дата готовности груза, план",
    "contacts.shipper": "Грузоотправитель",
    "partner.geography": "География присутствия",
    "form.field.commission.load_error": "Профилей не задано",
    "crudForm.field.radiusTariff": "Радиусный тариф",
    "importTranslates.importFile": "Загрузите файл",
    "crudForm.field.detention": "Детеншн, (дн)",
    "partner.rates.zip": "С учётом ZIP-кодов",
    "crud.filter.nds.label": "НДС (вкл / не вкл)",
    "crudForm.field.period": "Период",
    "form.order.consignee": "Получатель",
    "order.createNewManual.title": "Оформить новый заказ",
    "operatorAdmin.directories.countryCategories": "Страхование. Категории стран",
    "crud.disclaimers.railway.railwayCompanyName": "ж/д оператор",
    "pallet.not.tariffs": "Не найден ни один тариф",
    "indicator.customsClearance.arrival.plan": "Таможенное оформление, прибытие (план)",
    "common.save.success": "Данные успешно сохранены",
    "crud.field.consolidator.title": "Консолидатор",
    "detailsOfCalculation.download": "Скачать",
    "disclaimers.title.modal": "Дополнительные условия перевозки",
    "crudForm.dictionary.field.code_lang.placeholder": "Введите код языка",
    "crudForm.field.commentDescription": "Комментарий",
    "subscription.request.status.PROCESSED": "В обработке",
    "orderForm.estPrice": "Цена, план:",
    "crud.field.CONTAINER": "Контейнеры",
    "form.field.user": "Пользователь",
    "LogisticDocType.Offer": "Коммерческое предложение",
    "login.welcome": "Вход на сайт",
    "form.field.commission.profile1.menu": "Автоперевозка",
    "person.quotation.reference": "Расчет №",
    "wizard.tooltip.selectContainer20": "Выбрать контейнер.",
    "operatorAdmin.directories.customsClearance": "Таможенное оформление (условия)",
    "report.vehicle.tent": "Тентованный фургон",
    "partner.rates.billOfAir": "Авианакладная",
    "reports.lineChart.countOfClosed": "Закрытые",
    "crudForm.field.edit": "Редактирование",
    "orderForm.modal.numberOfPlaces": "Количество мест:",
    "operatorAdmin.directories.lineConditions": "Условия линий",
    "common.loading": "Загрузка",
    "constants.typeOfCargoFull.lcl": "Сборный груз",
    "templates.documents.table.headers.actions": "Действия",
    "crud.field.moderationRequired.title": "Требует модерации",
    "operatorAdmin.audit": "Аудит",
    "subscription.request.status.ACCEPT": "Одобрена",
    "crudForm.bulkOperations.form.info.email": "Вы можете ввести несколько адресов почты через пробел",
    "excel.tariff.already.exist": "Данный тариф уже существует в системе",
    "crud.field.summary.title": "Количество запрошенных расчетов",
    "common.documents.addBtnTitle": "добавить документ",
    "report.cargo.vehicle": "Транспортное средство",
    "common.documents.error.id": "Нет доступных Вам заказов с указанным номером",
    "common.greaterThanOne": "Значение должно быть больше 1",
    "order.location.airportId.required": "Поле обязательно к заполнению",
    "crud.disclaimers.airline.conditionText": "Дисклеймер",
    "modal.currentUserInfo.success": "Данные профиля обновлены",
    "partner.rates.excel.tariffs": "Excel тарифы",
    "deliveryOrders.status.COMPLETED": "Завершен",
    "crudForm.field.routeType": "Направление воздушных линий",
    "form.field.importer": "Импортер",
    "TTH": "Товарно-транспортная накладная:",
    "constants.semiannual": "Полгода",
    "crudForm.dictionary.field.code": "Символьный код",
    "partner.rates.intraportForwarding": "Внутрипортовое экспедирование",
    "deliveryOrders.status.PICKED_UP": "Груз забрали",
    "tracking.already.exists": "Трекинг для данного груза уже существует",
    "crudForm.dictionary.field.code_currency": "Код валюты",
    "ratingReport.noResult": "из них без результатов",
    "common.greaterThanZero": "Значение должно быть больше 0",
    "indicator.pointOfArrival.arrival.plan": "Дата прибытия в хаб (план)",
    "form.field.organization.legalAddress.placeholder": "Введите адрес регистрации",
    "constants.vehicleBodyType.tent": "Тентованный фургон",
    "crud.field.airport.title": "Аэропорт",
    "reports.lineChart.count": "Количество",
    "detailsOfCalculation.alert.containsArtificialCalculation": "Расчет неполный, пожалуйста, уточните цену дополнительно",
    "detailsOfCalculation.orderDetails.btnName": "Дополнительные данные",
    "notifications.type.ExpiredCalculation.title": "Расчет № order.Id устарел",
    "constants.ordersSortFilter.bestTime": "Времени доставки",
    "form.field.includeInCost": "Включить в стоимость",
    "form.field.phone.placeholder": "Введите номер телефона",
    "crud.disclaimers.railway.cargoTypeName": "тип груза",
    "partner.bankDetails.form.field.bankName": "В банке",
    "ordersList.button.getMaxInfo": "Отображать в расширенном виде",
    "crud.field.status.title": "Статус",
    "reports.barChart.REGION": "Регионы",
    "indicator.pointOfPickup.departure": "Дата отправления ",
    "detailsOfCalculation.filters.remove.text": "Параметры успешно поиска очищены",
    "crud.field.selectTemplate.title": "Выберите шаблон",
    "constants.ordersSortFilterCurrency.default": "МУЛЬТИ",
    "form.wizard.title.cargo": "Груз",
    "userRegistration.uniqueEmail": "Пользователь с указанным адресом электронной почты уже зарегистрирован в системе",
    "person.delivery.status.title": "Status",
    "operatorAdmin.directories.statuses": "Статусы",
    "constants.vehicleBodyType.refrigerator": "Рефрижераторный фургон",
    "common.documents.error.file": "Размер файла более 20 Мб",
    "wizard.insuranceForm.value": "Стоимость",
    "crud.field.factAddress.title": "Факт. адрес",
    "orderForm.warning.shipperInfoMessage": "Информация о грузоотправителе",
    "passwordRecovery.link": "Восстановить",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.date.to": "Неверная дата окончания действия тарифа. Строка {0}, столбец {1}.",
    "indicator.deliveryToRecipientDate.fact": "Дата доставки получателю (факт)",
    "tracking.fields.place": "Город",
    "partner.rates": "Тарифы",
    "form.field.attachmentDocuments": "Прикрепленные документы",
    "operatorAdmin.directories.documentsFlow": "Документооборот",
    "operatorAdmin.listOfAPIsAgora": "Внешние API (Agora)",
    "crud.field.resetUsageLimit.title": "Сбросить количество использованных запросов",
    "crudForm.dictionary.field.id.placeholder": "ID",
    "reports.lineChart.countOfCanceled": "Отмененные",
    "wizard.cargo.form.vehiclePayload": "Тип кузова",
    "detailsOfCalculation.railway_station_departure": "Услуги на станции отправления",
    "detailsOfCalculation.fields.sellerReward": "Вознаграждение продавца",
    "crudForm.field.schedule": "Расписание",
    "templates.documents.table.headers.lang": "Язык",
    "crud.field.countVariantsCalculations.title": "Количество вариантов расчетов",
    "detailsOfCalculation.calcID": "Номер расчёта",
    "partner.bankDetails.form.field.address": "Юридический адрес",
    "constants.ordersSortFilterCurrency.rub": "RUB",
    "partner.services.serviceTypes.serviceCategories": "Категории услуг",
    "subscription.main.infinite.text": "Бесконечные расчеты: ",
    "crud.field.interestType.title": "Вид груза",
    "crud.field.transitTime.title": "Транзитное время",
    "constants.nds.zero": "0",
    "deliveryOrders.listItem.orderNumber": "№ заказа",
    "crud.field.zipCode.title": "Индекс",
    "crudForm.field.thresholdPrice": "Цена за кг",
    "customsOfCargo.fields.nds": "НДС, 20%",
    "RAILWAY": "Ж/Д накладная:",
    "pallet.tariff.not.found.ranges": "Не удалось определить интервалы для паллет при междугородней перевозке",
    "crud.field.port.title": "Порт",
    "crud.field.uid.title": "UID",
    "crudForm.field.notWork": "Не действует",
    "form.field.limitDays": "кол-во дней для отображения",
    "partner.userInfo.form.email": "Email",
    "crud.uploadFromExcel": "Добавить из Excel",
    "crud.field.languageEmail.title": "Язык уведомлений",
    "form.order.bill.cargoDescription": "Описание груза",
    "detailsOfCalculation.insurance.insuranceCost": "Стоимость страховки",
    "operatorAdmin.directories.logisticDocumentKindTitle": "Виды транспортных документов",
    "dialog.confirm.no": "Нет, отмена",
    "crud.disclaimers.vehicle.chargingName": "тарификация",
    "modal.orderInfo.general": "Сборный груз",
    "crud.field.modifier.private": "Приватный",
    "crud.field.organization.title": "Организация",
    "common.required.commonRanges": "Необходимо добавить 1 общедоступный диапазон",
    "common.documents.error.fieldsWithoutFile": "Заполните все поля",
    "orderForm.trackingNumber": "Номер отслеживания",
    "orderForm.containerNumber.placeholder": "Номер контейнера",
    "orderForm.aviaNumber.placeholder": "Номер авианакладной",
    "orderForm.idContainer": "Номер контейнера:",
    "radius.delete": "Удалить",
    "customsOfCargo.form.price.title": "Сумма платежей за весь груз",
    "modal.changePassword.oldPassword": "Старый пароль",
    "tracking.fields.search": "Коды груза у партнёров",
    "constants.month": "Месяц",
    "reports.types.DICTIONARY_COUNT": "Количество объектов системы",
    "deliveryOrders.listItem.cargoType": "Тип груза",
    "form.field.organizationName": "Наименование организации",
    "logistBlock.loadingDate": "Погрузка",
    "confirm.order.success": "Ваш заказ успешно сформирован",
    "directionOfTransportation.arrival": "Прибытие",
    "partner.generalInformation.form.field.profileStatus.APPROVED": "Подтвержден",
    "crud.field.countryTo.title": "Куда",
    "wizard.directionForm.title": "Выберите один из следующих пунктов:",
    "constants.routeType.import": "Импорт",
    "constants.ordersSortFilter.bestPrice": "По цене",
    "operatorAdmin.clients": "Клиенты",
    "crudForm.bulkOperations.nameCode.addSurcharges": "Добавить надбавки",
    "widget.order.number": "Ваш заказ",
    "crud.filter.hide": "Скрыть",
    "crudForm.field.reverseTariff": "Реверсивный тариф",
    "trunking.form.counterLastString": "тарифов.",
    "person.calculationInfo.update": "Обновить",
    "constants.charging.betweenTheCityPerDistance": "Между городами (за км)",
    "reports.field.dateTo": "До",
    "deliveryOrders.orderList.status.standby": "приостановлен",
    "user.passwordChanged": "Пароль успешно изменен",
    "reports.barChart.CURRENCY": "Валюта",
    "form.select.defaultPlaceholder": "Выбрать...",
    "form.field.postcode.placeholder": "Введите почтовый код",
    "deliveryOrders.orderInfo.address": "Адрес",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.docdeadline": "Неверно указан срок подачи документов. Строка {0}, столбец {1}.",
    "ratingReport.noOrderData": "Расчет удален",
    "modal.orderInfo.vehicle": "Машина",
    "person.order.number": "Заказ №",
    "crudForm.field.to": "Действует до",
    "form.field.shippingDate": "Планируемая дата готовности",
    "reports.lineChart.countOfOrder": "Количество запрошенных расчетов",
    "detailsOfCalculation.orderDetails.title": "Дополнительные данные",
    "form.wizard.title.cargoItem": "Груз",
    "common.roleManager": "Менеджер организации",
    "constants.charging.ADR_ADR": "Адрес ⇄ Адрес",
    "site.user.logoutSuccess": "Вы успешно вышли из своего аккаунта",
    "crud.field.category.title": "Категория",
    "operatorAdmin.directories.interestTypes": "Страхование. Виды грузов",
    "partner.generalInformation.form.field.legalAddress.placeholder": "Введите юридический адрес",
    "crudForm.bulkOperations.error": "Ошибка",
    "crud.field.hatchSize.title": "Размер люка самолета",
    "crud.field.enableLimit.title": "Вкл. учет лимитов",
    "crud.field.timeOfAction": "Срок действия",
    "operatorAdmin.directories.additions": "Доплата",
    "subscription.page.config.enable": "Состояние системы подписок (отключено/включено)",
    "deliveryOrders.cargoInformation.cargoSizeTitle": "Габариты",
    "crudForm.field.ports": "Порты",
    "wizard.customsOfCargo.containerRP": "контейнера",
    "deliveryOrders.cargoTrackingTitle": "Отслеживание",
    "deliveryOrders.orderList.headerTable.totalPrice": "Цена",
    "ratingReport.pressBtnForGenerateReport": "Нажмите на кнопку \"Сформировать отчет\" для получения данных отчета",
    "crudForm.field.seaLines": "Морская линия",
    "page.expiredTariffs.title": "Уважаемый пользователь, у ваших тарифов истекает срок действия",
    "reportResource.report.countTariffs": "Количество полученных тарифов",
    "crud.field.firstName.title": "Имя",
    "logistBlock.export": "Вывоз",
    "importTranslates.select.sheet": "Лист в книге",
    "crudForm.dictionary.field.code_lang": "Код языка",
    "service.reloadThePage": "Обновить страницу",
    "constants.weightUnits.lb": "lb",
    "crud.field.modifier.public": "Общедоступный",
    "crud.field.error.railway.railwayTariffExists": "Невозможно удалить железнодорожную станцию, для которой существуют тарифы на ж/д перевозку",
    "HOME_BOL_WITHOUT_SYMBOL": "Домашний коносамент",
    "trunking.international.form.defaultFileName": "Тариф на международную магистральную перевозку",
    "register.link.text": "Нет аккаунта?",
    "form.order.contacts": "Ваши контактные данные",
    "indicator.receiptOfDocuments": "Дата получения документов",
    "crudForm.field.intraportForwarding.container.from": "Контейнеров от",
    "partner.geography.officeAndBranches": "Офисы и филиалы",
    "crudForm.field.palletType": "Тип паллета",
    "indicator.customsClearance.departure": "Таможенное оформление, отправление",
    "partner.rates.airportServices": "Услуги в аэропорту",
    "HOME_AWB_WITHOUT_SYMBOL": "Домашняя авианакладная",
    "tracking.fields.key": "Ключ",
    "logistBlock.fact": "Факт",
    "crudForm.dictionary.field.coordinates.latitude.placeholder": "Широта",
    "crud.field.condition.title": "Текст условий",
    "crud.field.error.airport.roadTariffExists": "Невозможно удалить аэропорт, для которой существуют тарифы на автоперевозку",
    "crudForm.dictionary.field.description": "Описание",
    "constants.weightUnits.kg": "кг",
    "detailsOfCalculation.calcFounded": "Расчетов найдено:",
    "contacts.confirm.success": "Извещаемая сторона удалена",
    "form.field.seaPort": "Выбрать морской порт",
    "crudForm.field.common.documents.file": "Загрузите новый файл в любом формате, не более 20 Мб",
    "form.field.countryNew": "Начните вводить название страны и выберите из списка",
    "partner.generalInformation.form.field.name": "Наименование",
    "crud.field.surcharge.code.title": "Код",
    "subscription.modal.add.title": "Оформление новой подписки",
    "reports.lineChart.countOfAuto": "Автоперевозка",
    "HOME_BOL": "Домашний коносамент:",
    "crudForm.field.serviceType": "Тип услуги",
    "form.field.commission.placeholder": "Введите данные",
    "wizard.cargo.title": "Груз",
    "wizard.cargo.form.containers.title": "Контейнеры",
    "crud.tabs.historyCalculation": "История расчетов",
    "pallet.no.identify.direction": "Не удалось найти главный город",
    "crud.field.limit.title": "Запросы (в месяц)",
    "trunking.plato.platoRate": "Ставка за км",
    "wizard.subscribe.placeholder": "Ваш email",
    "constants.customHouseAir.inDestinationPlace": "Таможенное оформление в аэропорту прибытия",
    "partner.personalOrders": "Заявки",
    "profile.welcome.title": "Добро пожаловать",
    "crudForm.field.nds": "НДС, (%)",
    "constants.ordersSortFilterCurrency.eur": "EUR",
    "form.field.commission.type": "Тип",
    "wizard.cargo.form.containers.add": "Добавить",
    "form.field.shippingDate.placeholder": "Введите дату отгрузки",
    "crud.filter.value": "Значение",
    "userRegistration.invalidConfirmationCode": "Неверный код подтверждения",
    "constants.placeTypes.address": "Адрес",
    "templates.documents.edit.form.alert.nofile": "Для продолжения необходимо прикрепить файл.",
    "crud.field.dateCreated.title": "Дата создания",
    "reports.barChart.WORLD_PART": "Части света",
    "crud.field.error.geoObject.wrongLongitude": "Долгота должна быть в диапазоне от -180 до 180",
    "detailsOfCalculation.authLink": "Для просмотра подробной информации о расчетах необходимо {register} или {logIn}",
    "crudForm.dictionary.field.hatchSize": "Размер люка самолета",
    "constants.tarifications.private": "Относительная",
    "indicator.receiptOfDocuments.plan": "Дата получения документов (план)",
    "detailsOfCalculation.outdatedCalculationI.temMsg": "Расчет устарел. Обновите расчет, чтобы оформить заявку.",
    "crudForm.field.commission": "Вознаграждение включено",
    "crud.field.error.wordRegion.organizationExists": "Невозможно удалить часть света, используемую в географии присутствия компании",
    "detailsOfCalculation.saved": "Сохранено",
    "reports.alert.fail": "Произошла ошибка",
    "report.transportation.carriage": "(довоз до двери)",
    "crudForm.bulkOperations.field.selectOperation": "Действие",
    "deliveryOrders.orderInfo.shipperAndConsignee": "Грузоотправитель/Грузополучатель",
    "crudForm.field.common.documents.orderId": "Номер заказа",
    "report.unit.tn": "т",
    "deliveryOrders.orderList.headerTable.destinationPoint": "Куда",
    "site.header.contactUs": "Связаться с нами",
    "report.cargo.bill": "B/L",
    "crudForm.dictionary.field.maxWeight": "Макс. вес",
    "crud.field.internalPartners.title": "Внутренние партнеры",
    "crud.field.longitude.title": "Долгота",
    "crudForm.field.findTimetable": "Найти расписание",
    "constants.ordersSortFilterCurrency.usd": "USD",
    "common.btn.ready": "Готово",
    "crud.field.billing.title": "Тарификация",
    "wizard.customsOfCargo.containerMn": "контейнеров",
    "trunking.form.uploadSuccess": "Файл успешно загружен",
    "detailsOfCalculation.orderTable.pcs": "шт",
    "partner.users.title": "Пользователи",
    "detailsOfCalculation.fields.amount": "Кол-во",
    "register.client.title": "Зарегистрироваться как клиент",
    "crudForm.dictionary.field.comment": "Комментарий",
    "partner.userInfo.form.lastName.placeholder": "Введите фамилию",
    "reports.barChart.CITY": "Города",
    "notifications.new": "У Вас новое уведомление",
    "crud.field.error.seaLine.freightTariffExists": "Невозможно удалить морскую линию, для которой существуют тарифы фрахта",
    "confirm.invalid.link": "Неверная ссылка, обратитесь к администратору.",
    "crudForm.bulkOperations.nameCode.sendEmail": "Отправить по почте",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.fifo.container.40": "Неверная стоимость для FIFO контейнера 40. Строка {0}, столбец {1}.",
    "page.expiredTariffs.trunking": "Магистральная перевозка",
    "detailsOfCalculation.fields.bl": "B/L",
    "common.status.completed": "Выполнено",
    "trunking.form.sendBtn": "Загрузить",
    "crud.tab.organization.bank": "Банковские реквизиты",
    "form.order.selectDate": "Выберите дату",
    "LogisticDocType.ORDER_TO_LOGISTICIAN": "Поручение логисту",
    "deliveryOrders.orderList.header": "Мои заказы",
    "common.nextDateScan": "Дата следующей полного обновления статистики",
    "logistBlock.arrivalDate": "Прибытие",
    "crud.field.subscriptionTariffPlan.title": "Подписка",
    "crud.field.externalAPI.manage.title": "Управление",
    "deliveryOrders.status.DELIVERED": "Доставлен",
    "partner.bankDetails.form.field.bankName.placeholder": "Введите название банка",
    "indicator.pointOfPickup.load.fact": "Дата погрузки (факт)",
    "documents.replace.denied": "Такой документ уже загружен другим пользователем, поэтому заменить новым невозможно",
    "crud.tab.organization.acts": "Счета и Акты",
    "crudForm.bulkOperations.nameCode.removeElements": "Удалить записи",
    "detailsOfCalculation.makeOrder": "Оформить заявку",
    "crudForm.field.seaLine": "Линия",
    "crud.field.typeOfCargo.title": "Тип груза",
    "customsOfCargo.fields.customsDuty": "Таможенная пошлина",
    "crud.field.worldPartId.title": "Часть света",
    "deliveryOrders.cargoTracking.table.billing": "Тарификация",
    "subscription.switchToFree": "Подтвердите действие",
    "crudForm.bulkOperations.nameCode.removeSurcharges": "Удалить надбавки",
    "crudForm.dictionary.field.volume": "Объем, (м куб.)",
    "crud.field.oldVersion.title": "Старое значение",
    "detailsOfCalculation.fields.currency": "Валюта",
    "crudForm.field.weightTo": "До (кг)",
    "ru.cargobar.imports.tariff.ContainerSeaFreightTariffImportServic.fifo.container.20": "Неверная стоимость для FIFO контейнера 20. Строка {0}, столбец {1}.",
    "orderForm.editActionTitle": "редактировать",
    "crud.label.no": "Нет",
    "crud.field.user.title": "Пользователь",
    "service.unavailable.text": "Сервис временно недоступен.",
    "person.calculations": "Расчеты",
    "form.field.middleName.placeholder": "Введите отчество",
    "crudForm.field.consolidator": "Консолидатор",
    "common.emailInvalid": "Неверный адрес электронной почты",
    "crud.field.countries.title": "Страны",
    "form.field.languageEmail": "Язык уведомлений",
    "constants.month.october": "Октябрь",
    "deliveryOrders.orderList.headerTable.status": "Статус",
    "importTranslates.skipRows": "Пропустить строки",
    "modal.manage.externalAPI": "Управление внешними API",
    "crud.field.client.title": "Клиент",
    "detailsOfCalculation.loader.title": "Идет расчет вариантов",
    "operatorAdmin.directories.railwayOperators": "Железнодорожные операторы",
    "partner.geography.geoTypes.worldPart": "Часть света",
    "templates.documents.edit.form.lang": "Язык шаблона",
    "crudForm.field.airport": "Аэропорт",
    "common.field.from": "от",
    "SEA_PORT": "Порт",
    "organizationsProfiles.status.APPROVED": "Одобрен",
    "crudForm.button.addEmail": "Добавить эл. почту",
    "detailsOfCalculation.fields.byHour": "Почасовая",
    "contacts.toNotify": "Известить",
    "deliveryOrders.orderList.status.pickedUp": "груз забрали",
    "indicator.pointOfOrigin.departure": "Дата отправления из хаба отправления",
    "deliveryOrders.orderList.headerTable.dateReady": "Дата готовности",
    "crudForm.field.regionAbsent": "Отсутствует",
    "crudForm.field.kg": "кг",
    "partner.userInfo.form.phone": "Телефон",
    "constants.placeTypes.airport": "Аэропорт",
    "common.placeholder": "Введите данные",
    "pallet.tariff.already.exist": "Данный тариф уже существует в системе",
    "deliveryOrders.status.CANCELLED": "Отменен",
    "templates.documents.edit.form.header.new": "Создание шаблона",
    "form.field.commission.integrator.title": "Вознаграждение экспедитора",
    "form.field.confirmCode.placeholder": "Введите код",
    "indicator.pointOfOrigin.departure.fact": "Дата отправления из хаба (факт)",
    "crudForm.field.transitTime": "Транзитное время, (дн)",
    "detailsOfCalculation.fields.includeVat": "включая НДС",
    "general.cargo.air.too.big": "Груз с данными габаритами не проходит в люк самолета. Попробуйте изменить параметры груза или свяжитесь с нами для уточнения параметров перевозки.",
    "partner.bankDetails.form.field.numberAccount.placeholder": "Укажите номер расчетного счета",
    "reports.alert.success": "Действие успешно завершено",
    "logistBlock.departureDate": "Отправление",
    "form.field.limitMounthScan": "Период сбора статистики (в месяцах).",
    "report.transportation.pickup": "(довоз до двери)",
    "palletTariff.replaceFile": "Загрузите новый файл",
    "reportResource.report.countUseApi": "Количество обращений к внешним API",
    "crud.filter.yes": "Да",
    "detailsOfCalculation.defaultReportHeader": "Отчет",
    "templates.documents.edit.form.save": "Сохранить",
    "detailsOfCalculation.callManager.alert": "Цена требует уточнения",
    "crudForm.dictionary.field.allowCreateCalculations4Guest":"Гостевые расчеты",
    "deliveryOrders.orderBlock.orderActions.moveToCalculation":"Перейти к расчету",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.currency.empty":"Неверно заполнена валюта. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.currency.not.found":"Отсутствует валюта в системе. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.date.from":"Неверная дата начала действия тарифа. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.date.to":"Неверная дата окончания действия тарифа. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.hub.from.empty":"Неверная станция отправления. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.hub.from.not.found":"Отсутствует станция отправления в системе. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.hub.to.empty":"Неверная станция прибытия. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.hub.to.not.found":"Отсутствует станция прибытия в системе. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.operator.empty":"Неверный код жд оператора. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.operator.not.found":"Отсутствует морская линия в системе. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.transitTime":"Неверно указано транзитное время. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerRailwayTariffImportService.fifo.containers":"Нет ни одной стоимости перевозки. Строка {0}.",
    "wizard.cargo.form.size.totalVolume":"Общий объем",
    "wizard.cargo.form.size.totalWeight":"Общий вес",
    "wizard.cargo.form.CubVolume.placeholder.M3":"Куб. метры",
    "wizard.cargo.form.CubVolume.placeholder.FT3":"Куб. фунты",
    "report.unit.m3":"куб. м",
    "report.unit.ft3":"куб. ft",
    "deliveryOrders.cargoInformation.cargoTypeCube":"Кубы",
    "crudForm.field.common.documents.logisticDoc.warning": "Для загрузки данного типа документа обязательно требуется заполнить данные о грузоотправителе и грузополучателе",
    "uploadFile.error.text":"При обработке файла возникли следующие ошибки:",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.currency.empty":"Неверно заполнена валюта. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.currency.not.found":"Отсутствует валюта в системе. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.date.from":"Неверная дата начала действия тарифа. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.date.to":"Неверная дата окончания действия тарифа. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.hub.from.empty":"Неверный хаб отправления. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.hub.from.not.found":"Отсутствует хаб отправления в системе. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.hub.to.empty":"Неверный хаб прибытия. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.hub.to.not.found":"Отсутствует хаб прибытия в системе. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.transitTime":"Неверно указано транзитное время. Строка {0}, столбец {1}.",
    "ru.cargobar.imports.tariff.ContainerComplexTariffImportService.fifo.containers":"Нет ни одной стоимости перевозки. Строка {0}.",
    "crud.field.ownerId.title":"Владелец",
    "crud.field.owner.title":"Владелец",
    "wizard.directionForm.disabledButtonHint":"Выберете другой тип груза",
    "files.type.logo":"Логотип",
    "files.page.title":"Файлы",
    "files.edit.form.header.edit":"Обновление файла",
    "files.edit.form.header.new":"Новый файл",
    "files.edit.form.alert.nofile":"Для продолжения необходимо прикрепить файл.",
    "files.edit.form.alert.exist":"Для данных параметров файл уже создан.",
    "files.table.headers.name":"Название",
    "files.table.headers.type":"Тип",
    "files.table.headers.actions":"Действия",
    "files.edit.form.btn.file":"Прикрепить файл",
    "files.edit.form.nofile":"Файл не выбран",
    "files.edit.form.save":"Сохранить",
    "files.edit.form.cancel":"Отмена",
    "crud.field.enableIntegratorReward.title":"Применять правила продаж",
    "crud.field.enableIntegratorRewardAgora.title":"Применять правила продаж (Agora API)",
    "crud.field.freight20.title":"Стоимость фрахт 20",
    "crud.field.freight40.title":"Стоимость фрахт 40",
    "crud.field.freight40HC.title":"Стоимость фрахт 40'HC",
    "common.errorEmptyFields":"Проверьте, что все поля заполнены",
    "common.addRow":"Добавить строку",
    "tableComponent.contextMenu.cut":"Вырезать",
    "tableComponent.contextMenu.copy":"Копировать",
    "tableComponent.contextMenu.paste":"Вставить",
    "tableComponent.contextMenu.deleteRow":"Удалить",
    "tableComponent.contextMenu.deleteRows":"Удалить строки",
    "tableComponent.contextMenu.insertRowBellow":"Вставить строку после",
    "tableComponent.contextMenu.duplicateRow":"Дублировать",
    "tableComponent.contextMenu.duplicateRows":"Дублировать строки",
    "crud.form.table.add":"Добавить из таблицы",
    "crud.form.table.edit":"Изменить в таблице",
    "common.selectDate":"Выберете дату",

    'filter.calculation.currencies': 'Валюта:',
    'filter.calculation.sort': 'Сортировка:',

    'crud.form.add.table.title': 'Ввод тарифов',
    'crud.form.edit.table.title': 'Изменение тарифов',
    'tableComponent.confirm.cancelChages': 'Внесенные изменения не сохранятся.',
    'tracking.fields.error.dateFactError': 'Выбранная дата не может быть ранее плановой даты готовности груза',
    'tracking.fields.error.datePlanError': 'Выбранная дата готовности груза не может быть ранее даты создания заказа',
    'templates.documents.type.APPLICATION_TRANSPORTATION':'Поручение экспедитору',
    "crudField.internationalCompany.label":"Международная",
    "crud.field.countryFromId.title":"Страна откуда",
    "crud.field.countryToId.title":"Страна куда",
    "htmlEditor.undo":"Отменить изменение",
    "htmlEditor.bold":"Полужирный",
    "htmlEditor.italic":"Курсив",
    "htmlEditor.strikeThrough":"Зачеркнутый",
    "htmlEditor.link":"Ссылка",
    "htmlEditor.clearFormatting":"Очистить форматирование",
    "settingsEmail.emailSender":"Email для ответа",
    "settingsEmail.nameSender":"Имя отправителя",
    "settingsEmail.footerRu":"Подпись внизу письма (русский язык)",
    "settingsEmail.footerEn":"Подпись внизу письма (английский язык)",
    "settingsEmail.hideAdvancedSettings":"Дополнительные настройки",
    "settingsEmail.showAdvancedSettings":"Дополнительные настройки",
    "settingsEmail.styles":"Дополнительные стили",
    "settingsEmail.styles.help":"Узнать о поддерживаемых свойствах CSS стилей можно по <a href='https://developers.google.com/gmail/design/css#supported_css'>ссылке</a>.",
    "settingsEmail.save":"Сохранить",
    "settingsEmail.sendTestEmail":"Отправить тестовое письмо",
    "settingsEmail.showTestEmail":"Предпросмотр",
    "crud.tab.organization.emailSettings":"Настройки email",
    "settingsEmail.styles.warning":"Данный раздел предназначен для дополнительной кастомизации стилей письма.",
    "crud.field.nameEn.title":"Название (English)",
    "crud.field.city.title":"Город",
    "crud.field.origin.title":"Отправление",
    "crud.field.product.title":"Тип груза",
    "crud.field.minPrice.title":"Минимальная цена",
    "crud.field.terminalProcessing.title":"Терминальная обработка",
    "crudForm.field.terminalProcessing":"Терминальная обработка",
    "crud.field.surcharges.title":"Надбавки",
    "table.tariffs.fillAuto":"Вводится автоматически",
    "tableTariffs.enterRates":"Для сохранения введите минимум одну ставку",
    "common.reset":"Сбросить",
    "common.removeAll":"Очистить все",
    "airTariffWeights.modal.title":"Введите диапазоны весов",
    "operatorAdmin.menu.directories.seaConditions":"Условия по морским перевозкам",
    "operatorAdmin.menu.directories.airlineDisclaimers":"Условия по авиаперевозкам",
    "operatorAdmin.menu.directories.railwayDisclaimers":"Условия по ж/д перевозкам",
    "operatorAdmin.menu.directories.vehicleDisclaimers":"Условия по автоперевозкам",
    "crud.field.maxClients.title":"Макс. кол-во клиентов",
    "users.usageClients":"Количество клиентов",
    "files.type.logo_inverted":"Логотип (меню)",
    "files.type.FAVICON":"Favicon",
    "crud.edit.generateXLSX":"Экспорт в Excel",
    "priceField.service.title":"Услуга",
    "priceField.typeService":"Услуга",
    "priceField.vat":"НДС",
    "priceField.comment":"Комментарий",
    "priceField.cost":"Стоимость",
    "priceField.costWithVat":"Стоимость с НДС",
    "priceField.total":"Итог",
    "priceField.totalAllCargo":"Итог по всем грузам",
    "priceField.add":"Добавить услугу",
    "priceField.cargo":"Груз",
    "deliveryOrders.cargoInformation.serviceType":"Услуги",
    "priceField.autoIncludeVat":"Авто расчет НДС",
    "cargoType.containers":"Контейнеры",
    "cargoType.box":"Коробки",
    "cargoType.pallet":"Паллеты",
    "cargoType.cube":"Кубы",
    "cargoType.car":"Машина",
    "services.show":"Показать",
    "services.hide":"Скрыть",
    "orderBlock.selectTypeHub":"Тип транзитного хаба",
    "orderBlock.selectHub":"Выберите транзитный хаб",
    "orderBlock.hubNumber":"Транзитный хаб №",
    "orderBlock.addTransitHub":"Добавить транзитный хаб",
    "cargoType.container":"Контейнер",
    "orderChangesHistory.cargo":"Груз",
    "common.saveStatus":"Сохранение данных",
    "dialog.saveUnsavedChanges.body":"У вас есть несохраненные изменения, вы хотите их сохранить?",
    "orderForm.sync":"Синхронизировать даты",
    "dialog.enableSyncDates.body":"Вы хотите включить синхронизацию дат? После включения даты отслеживания по всем контейнерам будут синхронизированы с текущей вкладкой.",
    "orderForm.enableAutoTrackingSystem": "Ввод статуса вручную",
    "tracking.field.POINT_OF_CUSTOMS_CLEARANCE.title":"Таможенное оформление",
    "tracking.field.INVOICE.title":"Счет",
    "deliveryOrders.status.WAITING_CARGO": "Ожидание готовности груза",
    "deliveryOrders.status.AWAITING_PAYMENT": "Ожидает оплаты счета",
    "deliveryOrders.status.COLLECTED_FROM_SHIPPER": "Груз забран у грузоотправителя",
    "deliveryOrders.status.BILL": "Выставить счёт",
    "deliveryOrders.status.CARGO_SENT": "Груз отправлен",
    "deliveryOrders.status.LOADING_COORDINATION": "Согласование погрузки",
    "deliveryOrders.status.ARRIVED_TO_TRANSIT_HUB": "Груз прибыл в транзитный пункт",
    "deliveryOrders.orderInfo.shipAndConsign": "Отправитель/Получатель",
    "deliveryOrders.cargoInformation.cargoTypeTitlefake": "Параметры груза",
    "form.order.BOL":"Коносамент",
    "CUSTOMS_CLEARANCE":"Таможенное оформление"
  },
}
