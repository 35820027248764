import { FormattedMessage } from 'react-intl';
import React from 'react';

const Link = (props) => {

  const {items, index, urlTracking} = props;

  const isShowLinkTrackingPage = () => {
    if (urlTracking || (items && items[index].trackingPageUrl)) {
      return true;
    } else return false;
  }

  const openTrackingPage = () => {
    if(urlTracking) {
      const url = urlTracking;
      window.open(url, '_blank');
    } else if (items && items[index].trackingPageUrl) {
      const url = items[index].trackingPageUrl;
      window.open(url, '_blank');
    }
  }

  return (
    <div>
      {isShowLinkTrackingPage() && <div
        className={'actions'}
        onClick={openTrackingPage}>
        <FormattedMessage id="deliveryOrders.orderBlock.orderActions.openTrackingPage"/> <a className={"btn-link"}><FormattedMessage id="orderForm.button.open"/></a>
      </div>}
    </div>
  );
}

export default Link;
